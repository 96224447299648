// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// We use the Material-UI React library as our front end design framework
import { Typography, Link, Box } from "@material-ui/core"

// The application will always have the footer present
export default function Footer(props) {

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <div style={{ margin: "auto", textAlign: "center" }}>
      <Box pt={4}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://kaufmanrossin.com/">
            {"Kaufman Rossin"}
          </Link>{" "}
          {new Date().getFullYear()}
        </Typography>
      </Box>

      <Typography
        component="h2"
        variant="body2"
        color="textSecondary"
        align="center"
        gutterBottom
      >
        {"TrakRI"}
      </Typography>
    </div>
  )
}

