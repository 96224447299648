// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// We use the Material-UI React library as our front end design framework
import LinearProgress from "@material-ui/core/LinearProgress"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
// We load our application css by only bringing in the styles needed for the current page
import { linearProgressWithLabelStyles } from "../../Config/theme"

// New Material-UI style progressbar with a label
export default function LinearProgressWithLabel(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = linearProgressWithLabelStyles()()
  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          className={classes.LinearProgress}
          variant="determinate"
          {...props}
        />
      </Box>
      <Box minWidth={50}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}
