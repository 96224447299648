// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// We use the Material-UI React library as our front end design framework
import { Typography, Fab, useScrollTrigger, Zoom } from "@material-ui/core"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
// We load our application css by only bringing in the styles needed for the current page
import { backToTopStyles } from "../../Config/theme"

// Whenever the user scrolls down a button will appear enabling single click return to the initial state of the page
export default function BackToTop(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = backToTopStyles()()
  // When the user clicks we send them back to the anchor element which we have set at the top of our application
  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    )

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }

  return (
    <Zoom in={useScrollTrigger()}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.backToTopRoot}
      >
        <Fab
          variant="extended"
          color="primary"
          size="medium"
          aria-label="scroll back to top"
        >
          <Typography variant="overline">Scroll To Top</Typography>
          <KeyboardArrowUpIcon />
        </Fab>
      </div>
    </Zoom>
  )
}
