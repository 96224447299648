// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// Each time the user navigates to a new "page" we switch out the HTML and CSS inside of Main using the Router path
import { Switch } from "react-router"
// Other than the landing page, every part of our application is required to locked behind authenticated private routes
import { PrivateRoute } from "../Auth"

// These are the web pages in this application:
// 1) The home page in the app is curently undesigned and has only a link to the clients - low hanging fruit
import Home from "./Home"
// 2) All resources in the application ultimatly belong to a Client which we tie all users to via the UserClient model for multi-tenanting
import Clients from "./Clients"
// 3) We enable our clients to use the tool as many times as they want and will tie a core 'loop' of the business logic to a unique engagement
import Engagements from "./Engagements"
// 4) Their are numerous types of input data required to be obtained from the client and transfered to the remote server for processing
import Sections from "./Sections"
// 5) An audit trail of actions taken by users
import AuditTrail from "./AuditTrail"
// 5) The user can make a number of selections and download as many list scrubs as they want from this dashboard
import Section from "./Section"
// 6) A help page with tips for the user
import Help from "./Help"


export default function Main(props) {
  // Push notificatiosn expect the websocket to be provided to them directly to prevent side effects when rerendering
  const parentProps = props
  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <Switch>
      <PrivateRoute exact path="/" render={(props) => <Home {...props} />} />
      <PrivateRoute
        exact
        path="/help"
        render={(props) => <Help {...props} />}
      />
      <PrivateRoute
        exact
        path="/clients"
        render={(props) => (
          <Clients
            {...props}
            cableApp={parentProps.cableApp}
            sidebarData={parentProps.sidebarData}
            setSidebarData={parentProps.setSidebarData}
          />
        )}
      />
      <PrivateRoute
        exact
        path="/clients/:clientId/engagements"
        render={(props) => (
          <Engagements
            {...props}
            cableApp={parentProps.cableApp}
            sidebarData={parentProps.sidebarData}
            setSidebarData={parentProps.setSidebarData}
          />
        )}
      />
      <PrivateRoute
        exact
        path="/clients/:clientId/engagements/:engagementId/sections"
        render={(props) => (
          <Sections
            handleDataChange={parentProps.handleDataChange}
            {...props}
            key={Math.random()} //Key is used to fix route renders
          />
        )}
      />
      <PrivateRoute
        exact
        path="/clients/:clientId/engagements/:engagementId/sections/audit_trail"
        render={(props) => (
          <AuditTrail
            handleDataChange={parentProps.handleDataChange}
            {...props}
            key={Math.random()} //Key is used to fix route renders
          />
        )}
      />
      <PrivateRoute
        exact
        path="/clients/:clientId/engagements/:engagementId/sections/:sectionId"
        render={(props) => (
          <Section
            handleDataChange={parentProps.handleDataChange}
            {...props}
            key={Math.random()} //Key is used to fix route renders
          />
        )}
      />
    </Switch>
  )
}
