// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// We use the Material-UI React library as our front end design framework
import Box from "@material-ui/core/Box"
import { Grid } from "@material-ui/core"
// We load our application css by only bringing in the styles needed for the current page
import { loadingRingStyles } from "../../Config/theme"

// We set default values here for the loading ring that can be overwritten for any specific instance when desired
LoadingRing.defaultProps = {
  color: "rgb(97, 144, 129)",
  style: {},
  size: 250,
}

// An endless spinning circle that is turned on and off when you make calls to the API
export default function LoadingRing({ color, className, style, size }) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = loadingRingStyles()()
  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <Grid
      container
      direction="row"
      justify="center"
      className={classes.loadingContainer}
    >
      <Box
        className={classes.ldsDualRing}
        justifyContent="center"
        style={{ width: size, height: size, ...style }}
      >
        <div
          className={classes.ldsDualRingAfter}
          style={{
            borderColor: `${color} transparent`,
            borderWidth: size * 0.1,
            width: size * 0.7 - 6,
            height: size * 0.7 - 6,
          }}
        />
      </Box>
    </Grid>
  )
}
