import React, { Component } from "react"
import { Route } from "react-router-dom"
import { AuthContext } from "./AuthContext"

export class PrivateRoute extends Component {
  static contextType = AuthContext

  render() {
    const {
      loading,
      isAuthenticated,
      isAuthorized,
      login,
      logout,
    } = this.context

    if (!loading && !isAuthenticated()) login()
    else if (!loading && !isAuthorized()) logout()

    return this.context.isAuthorized() ? <Route {...this.props} /> : null
  }
}
