import React, { useState } from "react"
import ReactPlayer from "react-player/lazy"
import { makeStyles } from "@material-ui/core/styles"
import {
  GridList,
  GridListTile,
  GridListTileBar,
  Fade,
  Modal,
  Typography,
  Box,
  Backdrop,
  ListSubheader,
} from "@material-ui/core"
import NavEngagements from "../assets/videos/2_bpm_menu-nav-to-engagements.mp4"
import NavSection from "../assets/videos/6_bpm_nav-section.mp4"
import AddClient from "../assets/videos/4_bpm_add-client+engagement.mp4"
import AddSection from "../assets/videos/6_bpm_add-section.mp4"
import AddActionItem from "../assets/videos/7_bpm_create-action-item.mp4"
import SectionsExpand from "../assets/videos/5_bpm_expand_section.mp4"
import DragDrop from "../assets/videos/8_bpm_drag-to-reorder-action-items.mp4"
import Upload from "../assets/videos/7_bpm_upload-file.mp4"
import Jump from "../assets/videos/6_bpm_jump-section.mp4"
import Delete from "../assets/videos/6_bpm_delete-section-cancel.mp4"
import Search from "../assets/videos/5_bpm_real-time-search.mp4"
import Export from "../assets/videos/5_bpm_export-csv.mp4"

const tileData = [
  {
    movie: AddClient,
    title: "Create: New Client and New Engagement",
  },
  {
    movie: AddSection,
    title: "Create: Add a New Section to an Engagement",
  },
  {
    movie: AddActionItem,
    title: "Create: Add a New Action Item to a Section",
  },
  {
    movie: NavEngagements,
    title: "Find: Engagement using the new Menu",
  },
  {
    movie: SectionsExpand,
    title: "View: Action Items from Sections",
  },
  {
    movie: DragDrop,
    title: "Change: Action Item position using drag-and-drop",
  },
  {
    movie: Upload,
    title: "Upload: Documents to Action Item",
  },
  {
    movie: Jump,
    title: "QuickJump: The fastest way to switch between Sections",
  },
  {
    movie: Search,
    title: "Find: Section using search function",
  },
  {
    movie: Delete,
    title: "Delete: Section",
  },
  {
    movie: NavSection,
    title: "Explore: View a Section and its Action Items",
  },
  {
    movie: Export,
    title: "Export: Sections data to CSV",
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    paddingTop: "7px",
    paddingBottom: "20px",
  },
  gridList: {
    width: "100%",
    height: "100%",
    // Promote the list into its own layer on Chrome. Trades memory for high FPS.
    transform: "translateZ(0)",
    "&:hover": {
      cursor: "pointer",
    },
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "1rem",
  },
}))

export default function Help() {
  const [open, setOpen] = useState(false)
  const [movie, setMovie] = useState(false)

  const classes = useStyles()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <GridList
        cols={4}
        cellHeight={400}
        spacing={7}
        className={classes.gridList}
      >
        <GridListTile key="Subheader" style={{ height: "auto" }}>
          <ListSubheader component="div">
            {"Click or tap on a video to view full-size."}
          </ListSubheader>
        </GridListTile>
        {tileData.map((tile, index) => (
          <GridListTile
            onClick={() => {
              setMovie(tile.movie)
            }}
            key={index}
            cols={1}
            rows={1}
          >
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                onClick={handleOpen}
                type="video/mp4"
                url={tile.movie}
                width="100%"
                controls={true}
              />
            </div>
            <GridListTileBar
              title={tile.title}
              titlePosition="top"
              actionPosition="left"
              className={classes.titleBar}
            />
          </GridListTile>
        ))}
      </GridList>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div id="transition-modal-title">
              {" "}
              <video id="background-video" loop autoPlay>
                <source src={movie} type="video/mp4" />
                <source
                  src="http://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_1mb.mp4"
                  type="video/ogg"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <Box
              style={{ marginTop: "15px" }}
              display="flex"
              justifyContent="space-between"
              id="transition-modal-description"
            >
              <Box display="flex" justifyContent="space-between">
                <Typography
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                  variant="body1"
                  component="h3"
                >
                  {"Click or tap anywhere outside of this dialog to return."}
                </Typography>
              </Box>
              <div />
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
