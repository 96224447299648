// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useState } from "react"
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link, useParams } from "react-router-dom"
// We use Auth0 as our current authenication and authorization solution
import { AuthContext } from "../Auth"
// All interactions with the remote server are driven from this file
import { useEngagements } from "../Utils/api"
// We use the Material-UI React library as our front end design framework
import { Hidden, Grid, Button } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
// We load our application css by only bringing in the styles needed for the current page
import { engagementsStyles } from "../Config/theme"
// Prevents rendering of the application and shows a spinning circle while the application is loading
import LoadingScreen from "../Components/Loading/LoadingRing"
// We use Material-Table for CRUD operations on application resources
import MaterialTable from "material-table"
import DataTableIcons from "../Components/Icons/DataTableIcons"
import { CreateRow, DeleteRow, EditRow } from "../Utils/api"
// Validation helpers for input fields in Material-Table
import { isAlphaNumericPlus } from "../Utils/validationHelpers"
import { colors } from '../Config/colors'

// The core function for the Engagements "web page"
export default function Engagements(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = engagementsStyles()()
  // Access the resource ids from the URL bar via the useParams hook
  const params = useParams()
  // When isLoading = true the application shows the spinning loading circle instead of the main content
  const [isLoading, setIsLoading] = useState(false)
  // The CRUD operations are handled by Material-Table which has its entire state driven by this gridData variable. You can
  // force a rerender of the HTML and CSS by using the setGridData function
  // The long term persistent data retrieved from your database goes into the data attribute
  // The display information for the table goes in the columns attribute. You need to look in the npm modules to see the default
  // HTML and CSS used by Material-Table. If you see a render: attribute that will contain custom HTML and CSS written by our
  // team to override the defualt Material-Table HTML and CSS
  const [gridData, setGridData] = useState({
    data: [],
    columns: [
      {
        title: "Name",
        field: "name",
        grouping: false,
        validate: (rowData) => validateEngagementName(rowData),
        render: (rowData) => (
          <Link
            to={{
              pathname: `/clients/${params.clientId}/engagements/${rowData.id}/sections`,
              state: {
                clientId: rowData.id,
                clientName: rowData.name,
              },
            }}
          >
            {rowData.name}
          </Link>
        ),
      },
    ],
  })
  // Front end validations are intended to be a guide to help the user give you what you need from them
  const validateEngagementName = (rowData) => {
    if (rowData.name === undefined) {
      return { isValid: false, helperText: "Required" }
    }

    if (rowData.name.length < 4) {
      return { isValid: false, helperText: "4 character minimum" }
    }

    if (rowData.name.includes("  ")) {
      return { isValid: false, helperText: "No double spaces" }
    }

    if (rowData.name.length > 30) {
      return { isValid: false, helperText: "30 character limit" }
    }

    if (isAlphaNumericPlus(rowData.name)) {
      return true
    } else {
      return {
        isValid: false,
        helperText: "Single spaces, 0-9, A-Z, a-z, _",
      }
    }
  }
  // Material-Table options
  const options = {
    headerStyle: {
      backgroundColor: colors.MIDNIGHT_BLUE.hex,
      color: "white",
    },
    sorting: true,
    rowHover: true,
    actionsColumnIndex: -1,
    filterType: "dropdown",
    pageSize: 10,
    exportButton: true,
    addRowPosition: "first",
  }
  // Material-Table names and labels overrides
  const localization = {
    pagination: {
      labelDisplayedRows: "{from}-{to} of {count}",
    },
    toolbar: {
      nRowsSelected: "{0} row(s) selected",
    },
    header: {
      actions: "Admin Actions",
    },
    body: {
      emptyDataSourceMessage: "No Engagements to display",
      filterRow: {
        filterTooltip: "Filter",
      },
    },
  }
  // Material-Table CRUD communication with the API
  const onRowDelete = DeleteRow(
    gridData,
    setGridData,
    "engagements",
    params,
    props
  )
  const onRowUpdate = EditRow(
    gridData,
    setGridData,
    "engagements",
    params,
    props
  )
  const onRowAdd = CreateRow(
    gridData,
    setGridData,
    "engagements",
    params,
    props
  )
  // Material-Table CRUD actions configuration
  const editable = {
    isEditable: (rowData) => true,
    isDeletable: (rowData) => true,
    onRowUpdate: onRowUpdate,
    onRowDelete: onRowDelete,
    onRowAdd: onRowAdd,
  }

  // The last thing we do after any other setup code and before rendering for the user is reach out to our API
  // for the persistent data that drives this "web page"
  useEngagements(params, setIsLoading, setGridData)

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  // This web page will display a loading circle when isLoading is set to true, otherwise it will display the actual content
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Consumer>
      {({ auth }) => (
        <>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item>
              <Link
                to={{
                  pathname: "/clients",
                  state: {},
                }}
              >
                <Button
                  title="Back to Clients"
                  className={classes.leftSideNavigationButton}
                >
                  <ArrowBackIcon /> {"Back to Clients"}
                </Button>
              </Link>
            </Grid>

            <Hidden>
              <Grid item />
              <Grid item />
            </Hidden>

            <Grid item xs={12}>
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  isLoading={isLoading}
                  data={gridData.data}
                  icons={DataTableIcons}
                  title=" Engagements"
                  columns={gridData.columns}
                  options={options}
                  localization={localization}
                  {...(auth.user.admin
                    ? { editable: editable }
                    : { editable: null })}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </AuthContext.Consumer>
  )
}
