// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link } from "react-router-dom"
// We load our application css by only bringing in the styles needed for the current page
import { clientListStyles } from "../../Config/theme"
// We use the Material-UI React library as our front end design framework
import {
  ListSubheader,
  Divider,
  ListItem,
  ListItemText,
  List,
  Collapse,
  ListItemIcon,
  Button,
} from "@material-ui/core"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import FolderSharedIcon from "@material-ui/icons/FolderShared"
// The following are HTML/CSS components that are present on this page
import EngagementListItem from "./EngagementListItem"

// This function displays the first level in our sidebar nav tree containing each unique client
export default function ClientListItem(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = clientListStyles()()
  // Drives the state for the list of engagements that is displayed beneath each unique client
  const [open, setOpen] = React.useState(false)
  // Helper function that sets open to closed and closed to open
  const handleClick = () => {
    setOpen(!open)
  }

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <>
      <ListItem button onClick={handleClick} className={classes.header}>
        <ListItemIcon className={classes.listItemIcon}>
          <FolderSharedIcon />
        </ListItemIcon>
        <ListItemText primary={props.client.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          subheader={
            <ListSubheader
              onClick={props.handleDrawerClick}
              component={Link}
              to={"/clients/" + props.client.id + "/engagements"}
              id="nested-list-subheader"
              className={classes.subHeader}
              disableSticky={true}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.manageResourceButton}
              >
                Manage Engagements
              </Button>
            </ListSubheader>
          }
        >
          {props.client.engagements.map((engagement, idx) => {
            return (
              <EngagementListItem
                key={idx}
                client={props.client}
                engagement={engagement}
                handleDrawerClick={props.handleDrawerClick}
              />
            )
          })}
        </List>
        <Divider />
      </Collapse>
    </>
  )
}
