import React from "react"
import { Button, Modal } from "react-bootstrap"
import DeleteIcon from "@material-ui/icons/Delete"

class DeleteResourceModal extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDeleteResource = this.handleDeleteResource.bind(this)

    this.state = {
      show: false,
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow() {
    this.setState({ show: true })
  }

  handleDeleteResource() {
    this.props.handleActionItemDeletion(this.props.resource.id, this.props.actionState, this.props.setActionState, this.props.contextValue)
    this.handleClose()
  }

  render() {
    return (
      <>
        <Button className={this.props.classNames} onClick={this.handleShow}>
          <DeleteIcon />
        </Button>
        <Modal
          className="new-modal"
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header className="custom-modal-header" closeButton>
            <Modal.Title>Delete {this.props.resourceType}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            WARNING! This will delete all data associated with the selected{" "}
            {this.props.resourceType}. This data is NOT recoverable.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              type="submit"
              onClick={this.handleDeleteResource}
            >
              Yes, Delete Forever
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default DeleteResourceModal
