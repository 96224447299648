// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link } from "react-router-dom"
// We load our application css by only bringing in the styles needed for the current page
import { engagementListStyles } from "../../Config/theme"
// We use the Material-UI React library as our front end design framework
import {
  //ListSubheader,
  //Divider,
  ListItem,
  ListItemText,
  //List,
  //Collapse,
  ListItemIcon,
} from "@material-ui/core"
//import ExpandLess from "@material-ui/icons/ExpandLess"
//import ExpandMore from "@material-ui/icons/ExpandMore"
//import Assignment from "@material-ui/icons/Assignment"
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode"
//import FindReplaceIcon from "@material-ui/icons/FindReplace"

// Stores the various web pages in the application that the user should be allowed to jump to
//const engagementSections = [
  //{ title: "File Uploads", url: "/fileUploads", icon: <Assignment /> },
  //{ title: "Scrubs", url: "/scrubs", icon: <FindReplaceIcon /> },
//]

// This function displays the second and third levels in our sidebar nav tree containing each unique engagement and links to sections
export default function EngagementListItem(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = engagementListStyles()()
  // Drives the state for the list of engagements that is displayed beneath each unique client
  const [open, setOpen] = React.useState(false)
  // Helper function that sets open to closed and closed to open
  const handleClick = () => {
    setOpen(!open)
  }
  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <>
      <ListItem       
        onClick={props.handleDrawerClick}
        button
        className={classes.subHeaderItem}
        component={Link}
        to={
          "/clients/" +
          props.client.id +
          "/engagements/" +
          props.engagement.id +
          "/sections"
        }
      >
        <ListItemIcon className={classes.listItemIcon}>
          <ChromeReaderModeIcon />
        </ListItemIcon>
        <ListItemText primary={props.engagement.name} />
        {/* {open ? <ExpandLess /> : <ExpandMore />} */}
      </ListItem>
      {/* <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={classes.subHeader}
              disableSticky={true}
            >
              Workflow Sections
            </ListSubheader>
          }
        >
          {engagementSections.map((section, idx) => {
            return (
              <ListItem
                onClick={props.handleDrawerClick}
                key={idx}
                button
                className={classes.thirdLevel}
                component={Link}
                to={
                  "/clients/" +
                  props.client.id +
                  "/engagements/" +
                  props.engagement.id +
                  section.url
                }
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {section.icon}
                </ListItemIcon>
                <ListItemText primary={section.title} />
              </ListItem>
            )
          })}
        </List>
        <Divider />
      </Collapse> */}
    </>
  )
}
