// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import { useContext, useEffect } from "react"
// We keep track of anything that is context dependent on Development, Staging, or Production environment in one file
import env from "../Config/environment"
// We use Auth0 as our current authenication and authorization solution
import { AuthContext } from "../Auth/AuthContext"
// We use React Notifications to show pop up messages to the user
import { NotificationManager } from "react-notifications"
// We use Axios to make RESTful calls to our backend server. This API.js file is intended to contain all calls to the backend in
// one conveinent place, however note that File Uploads are an exception and contain an API call in their architecture
import axios from "axios"

// A helper function that constructs the environment dependent section
const API = axios.create({
  baseURL: env.API_BASE_URL,
})

// These functions are hooks and represent the API calls needed on initial load or refresh of one of our core "web pages"
// They represent the "R" in "CRUD"
// Web Page 0) App
function useSidebar(setIsLoading, setData) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    async function getData() {
      return await axios.all([
        API.get("clients", {
          params: {},
          headers: contextValue.getTokens(),
        }),
      ])
    }

    setIsLoading(true)

    getData()
      .then(
        axios.spread(function (clients) {
          if (mounted) {
            setIsLoading(false)
            setData((prevState) => ({
              ...prevState,
              clients: clients.data,
            }))
          }
        })
      )
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          console.log(error)
        }
      })
    return () => (mounted = false)
  }, [setData, contextValue, setIsLoading])
}
// Web Page 1) Home
// No persistent data associated with this web page at this time
// Web Page 2) Clients
function useClients(params, setIsLoading, setGridData) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    async function getData() {
      return await API.get("clients", {
        params: {},
        headers: contextValue.getTokens(),
      })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          setIsLoading(false)
          setGridData((prevState) => ({
            ...prevState,
            data: r.data,
            isLoading: false,
          }))
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          console.log(error)
        }
      })
    return () => (mounted = false)
  }, [params, setIsLoading, setGridData, contextValue])
}
// Web Page 3) Engagements
function useEngagements(params, setIsLoading, setGridData) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    async function getData() {
      return await API.get("engagements", {
        params: {
          client_id: params.clientId,
        },
        headers: contextValue.getTokens(),
      })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          setIsLoading(false)
          setGridData((prevState) => ({
            ...prevState,
            data: r.data,
            isLoading: false,
          }))
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          console.log(error)
        }
      })
    return () => (mounted = false)
  }, [params, setIsLoading, setGridData, contextValue])
}
// Web Page 4) Sections
function useSections(params, setGridData, setIsLoading) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true

    async function getData() {
      return await axios
        .create({
          baseURL: env.API_BASE_URL,
          headers: contextValue.getTokens(),
        })
        .get("sections", {
          params: {
            engagement_id: params.engagementId,
          },
        })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          setIsLoading(false)
          setGridData((prevState) => ({
            ...prevState,
            data: r.data.sections,
            actionItems: r.data.action_items,
            isLoading: false,
          }))
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          console.log(error)
        }
      })
    return () => (mounted = false)
  }, [params, setGridData, setIsLoading, contextValue])
}
function useEngagement(params, setEngagement) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    axios
      .create({
        baseURL: env.API_BASE_URL,
        headers: contextValue.getTokens(),
      })
      .get(`engagements/${params.engagementId}`, {
        params: {
          client_id: params.clientId,
        },
      })
      .then((r) => {
        if (mounted) {
          setEngagement(r.data)
        }
      })
      .catch((error) => {
        if (mounted) {
          console.log(error)
        }
      })
    return () => (mounted = false)
  }, [params, setEngagement, contextValue])
}
// Web Page 5) Section
function useSection(params, setActionState) {
  const contextValue = useContext(AuthContext)

  useEffect(() => {
    let mounted = true
    axios
      .all([
        axios.get(`${env.API_BASE_URL}/sections/${params.sectionId}`, {
          headers: contextValue.getTokens(),
        }),
        axios.get(`${env.API_BASE_URL}/sections`, {
          params: { engagement_id: params.engagementId },
          headers: contextValue.getTokens(),
        }),
      ])
      .then(
        axios.spread(function (section, sections) {
          if (mounted) {
            setActionState((prevState) => ({
              ...prevState,
              isLoading: false,
              sections: sections.data.sections,
              section: section.data.section,
              actionItems: section.data.action_items,
              fileUploads: section.data.file_uploads,
              title: section.data.section.title,
              roeComments: section.data.section.roe_comments_text,
              auditIssues: section.data.section.audit_issues_text,
              bodyText: section.data.section.body_text,
              deadline: section.data.section.deadline,
            }))
          }
        })
      )
      .catch((error) => {
        if (mounted) {
          console.log(error)
        }
      })
    return () => (mounted = false)
  }, [params, setActionState, contextValue])
}
// Web Page 6) Audit Trail
function useAuditTrail(params, setIsLoading, setGridData) {
  const contextValue = useContext(AuthContext)
  useEffect(() => {
    let mounted = true
    async function getData() {
      return await API.get("audit_trails", {
        params: {
          engagement_id: params.engagementId,
        },
        headers: contextValue.getTokens(),
      })
    }

    setIsLoading(true)

    getData()
      .then((r) => {
        if (mounted) {
          console.log(r.data)
          setIsLoading(false)
          setGridData((prevState) => ({
            ...prevState,
            data: r.data,
            isLoading: false,
          }))
        }
      })
      .catch((error) => {
        if (mounted) {
          setIsLoading(false)
          console.log(error)
        }
      })
    return () => (mounted = false)
  }, [params, setIsLoading, setGridData, contextValue])
}
// These functions service our integration of Material-Table and represent the rest of the "CUD" actions in "CRUD" for all of our database
// driven persistent resources
function CreateRow(gridData, setGridData, model, params, props) {
  const contextValue = useContext(AuthContext)

  return (newData) =>
    new Promise((resolve) => {
      const data = [...gridData.data]

      if (
        // Some data tables are used without any user input values (like the stat run one) in which case the newData field will be
        // undefined instead of a blank {}.  We want to allow the create function to go ahead as usual in this circumstance
        newData !== undefined &&
        newData !== null &&
        Object.keys(newData).length === 0
      ) {
        NotificationManager.error(
          "You have not entered any data.  You must enter data for at least one field before clicking the checkmark.",
          "Unable to Create With No Data",
          5000
        )
        resolve({
          data: gridData.data,
        })
      } else {
        if (model === "clients") {
          if (newData.name === undefined) {
            newData["name"] = ""
          }
          if (newData.krco_number === undefined) {
            newData["krco_number"] = ""
          }
          API.post(
            "clients",
            {
              name: newData.name,
              krco_number: newData.krco_number,
            },
            { headers: contextValue.getTokens() }
          )
            .then((response) => {
              if (response.data.error_message === undefined) {
                // The sidebar nav is driven by clients/engagements so we update its state as well
                props.sidebarData.clients.unshift(response.data)

                props.setSidebarData((prevState) => ({
                  ...prevState,
                  clients: props.sidebarData.clients,
                }))
              } else {
                setGridData({ ...gridData, data, resolve })
                resolve({
                  data: gridData.data,
                })
                return NotificationManager.error(
                  response.data.error_message,
                  response.data.error_title,
                  5000
                )
              }
            })
            .catch((error) => console.log(error))
        } else if (model === "engagements") {
          API.post(
            "engagements",
            {
              name: newData.name,
              client_id: params.clientId,
            },
            { headers: contextValue.getTokens() }
          )
            .then((response) => {
              if (response.data.error_message === undefined) {
                // The sidebar nav is driven by clients/engagements so we update its state as well
                let newClients = []

                props.sidebarData.clients.forEach((client) => {
                  if (client.id === response.data.client_id) {
                    client.engagements.unshift(response.data)
                    newClients.push(client)
                  } else {
                    newClients.push(client)
                  }
                })

                props.setSidebarData((prevState) => ({
                  ...prevState,
                  clients: props.sidebarData.clients,
                }))
              } else {
                setGridData({ ...gridData, data, resolve })
                resolve({
                  data: gridData.data,
                })
                return NotificationManager.error(
                  response.data.error_message,
                  response.data.error_title,
                  5000
                )
              }
            })
            .catch((error) => console.log(error))
        } else if (model === "sections") {
          API.post(
            "sections",
            {
              engagement_id: params.engagementId,
              section: {
                title: newData.title,
                body_text: "",
              },
            },
            { headers: contextValue.getTokens() }
          ).then((response) => {
            data.unshift(response.data)
            setGridData({ ...gridData, data, resolve })
            resolve({
              data: gridData.data,
            })
          })
        }
      }
    })
}
function EditRow(gridData, setGridData, model, params, props) {
  const contextValue = useContext(AuthContext)
  return (newData, oldData) =>
    new Promise((resolve) => {
      const data = [...gridData.data]

      data[data.indexOf(oldData)] = newData

      if (model === "clients") {
        API.put(
          `clients/${newData.id}`,
          {
            name: newData.name,
            krco_number: newData.krco_number,
          },
          { headers: contextValue.getTokens() }
        )
          .then((response) => {
            if (response.data.error_message === undefined) {
              // The sidebar nav is driven by clients/engagements so we update its state as well
              let newClients = []
              props.sidebarData.clients.forEach((client) => {
                if (client.id === response.data.id) {
                  newClients.push(response.data)
                } else {
                  newClients.push(client)
                }
              })
              props.setSidebarData((prevState) => ({
                ...prevState,
                clients: newClients,
              }))
            } else {
              resolve({})
              return NotificationManager.error(
                response.data.error_message,
                response.data.error_title,
                5000
              )
            }
          })
          .catch((error) => console.log(error))
      } else if (model === "engagements") {
        API.put(
          `engagements/${newData.id}`,
          {
            name: newData.name,
          },
          { headers: contextValue.getTokens() }
        )
          .then((response) => {
            if (response.data.error_message === undefined) {
              // The sidebar nav is driven by clients/engagements so we update its state as well
              let newClients = []

              props.sidebarData.clients.forEach((client) => {
                if (client.id === response.data.client_id) {
                  let newEngagements = []
                  client.engagements.forEach((engagement) => {
                    if (engagement.id === response.data.id) {
                      newEngagements.push(response.data)
                    } else {
                      newEngagements.push(engagement)
                    }
                  })
                  client.engagements = newEngagements
                  newClients.push(client)
                } else {
                  newClients.push(client)
                }
              })

              props.setSidebarData((prevState) => ({
                ...prevState,
                clients: props.sidebarData.clients,
              }))
            } else {
              resolve({})
              return NotificationManager.error(
                response.data.error_message,
                response.data.error_title,
                5000
              )
            }
          })
          .catch((error) => console.log(error))
      } else if (model === "users") {
        API.put(
          `users/${newData.id}`,
          {
            email: newData.email,
            admin: newData.admin,
            first_name: newData.first_name,
            last_name: newData.last_name,
            client_manager: newData.client_manager,
          },
          { headers: contextValue.getTokens() }
        )
          .then((response) => {})
          .catch((error) => console.log(error))
      } else if (model === "sections") {
        API.put(
          `sections/${newData.id}`,
          {
            title: newData.title,
          },
          { headers: contextValue.getTokens() }
        ).then((response) => {})
      }

      setGridData({ ...gridData, data, resolve })
      resolve({
        data: gridData.data,
      })
    })
}
function DeleteRow(gridData, setGridData, model, params, props) {
  const contextValue = useContext(AuthContext)
  return (oldData) =>
    new Promise((resolve) => {
      if (model === "clients") {
        const data = [...gridData.data]
        data.splice(data.indexOf(oldData), 1)

        API.delete(`clients/${oldData.id}`, {
          headers: contextValue.getTokens(),
        })
          .then((response) => {
            // The sidebar nav is driven by clients/engagements so we update its state as well
            let newClients = []
            props.sidebarData.clients.forEach((client) => {
              if (client.id !== oldData.id) {
                newClients.push(client)
              }
            })
            props.setSidebarData((prevState) => ({
              ...prevState,
              clients: newClients,
            }))
          })
          .catch((error) => console.log(error))
      } else if (model === "engagements") {
        const data = [...gridData.data]
        data.splice(data.indexOf(oldData), 1)

        API.delete(`engagements/${oldData.id}`, {
          headers: contextValue.getTokens(),
        })
          .then((response) => {
            // The sidebar nav is driven by clients/engagements so we update its state as well
            let newClients = []

            props.sidebarData.clients.forEach((client) => {
              if (client.id === oldData.client_id) {
                let newEngagements = []
                client.engagements.forEach((engagement) => {
                  if (engagement.id !== oldData.id) {
                    newEngagements.push(engagement)
                  }
                })
                client.engagements = newEngagements
                newClients.push(client)
              } else {
                newClients.push(client)
              }
            })

            props.setSidebarData((prevState) => ({
              ...prevState,
              clients: props.sidebarData.clients,
            }))
          })
          .catch((error) => console.log(error))
      } else if (model === "sections") {
        const data = [...gridData.data]
        data.splice(data.indexOf(oldData), 1)

        API.delete(`sections/${oldData.id}`, {
          headers: contextValue.getTokens(),
        }).then((response) => {})

        setGridData({ ...gridData, data, resolve })
        resolve({
          data: gridData.data,
        })
      }
    })
}

// These functions are not hooks and represent API calls triggered by user action
// This function downloads the Status Report for Sections
function handleStatusReport(setDownloadedStatus, params, contextValue) {
  setDownloadedStatus((prevState) => ({
    ...prevState,
    loading: true,
  }))
  axios
    .get(`${env.API_BASE_URL}/download_status_report/${params.engagementId}`, {
      headers: contextValue.getTokens(),
      responseType: "blob", // Important!
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      // A client has paid to have custom report names so here we expand this to custom names for their two engaements
      if(params.engagementId == 47){
        link.setAttribute("download", "engagement_status_report MRA.pdf")
      }else if(params.engagementId == 45){
        link.setAttribute("download", "engagement_status_report CO.pdf")
      }else {
        link.setAttribute("download", "engagement_status_report.pdf")
      }

      document.body.appendChild(link)
      link.click()
      setDownloadedStatus((prevState) => ({
        ...prevState,
        loading: false,
        downloaded: true,
      }))
    })
    .catch((error) => {
      setDownloadedStatus((prevState) => ({
        ...prevState,
        loading: false,
      }))
      console.log(error)
    })
}
// This function downloads the Snapshot Report for Sections
function handleSnapshotReport(setDownloadedSnapshot, params, contextValue) {
  setDownloadedSnapshot((prevState) => ({
    ...prevState,
    loading: true,
  }))
  console.log("hi mom")
  console.log(params)
  console.log(contextValue)
  axios
    .get(
      `${env.API_BASE_URL}/download_snapshot_report/${params.engagementId}`,
      {
        headers: contextValue.getTokens(),
        responseType: "blob", // Important!
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url

      // A client has paid to have custom report names so here we expand this to custom names for their two engaements
      if(params.engagementId == 47){
        link.setAttribute("download", `engagement_snapshot_report MRA.pdf`)
      }else if(params.engagementId == 45){
        link.setAttribute("download", `engagement_snapshot_report CO.pdf`)
      }else {
        link.setAttribute("download", `engagement_snapshot_report.pdf`)
      }
      
      document.body.appendChild(link)
      link.click()
      setDownloadedSnapshot((prevState) => ({
        ...prevState,
        loading: false,
        downloaded: true,
      }))
    })
    .catch((error) => {
      setDownloadedSnapshot((prevState) => ({
        ...prevState,
        loading: false,
      }))
      console.log(error)
    })
}
// This functino downloads the Section Status Report for Section
function handleSectionStatusReport(
  setDownloadedSectionStatus,
  params,
  contextValue,
  section
) {
  setDownloadedSectionStatus((prevState) => ({
    ...prevState,
    loading: true,
  }))
  axios
    .get(`${env.API_BASE_URL}/download_section_status_report/${section.id}`, {
      headers: contextValue.getTokens(),
      responseType: "blob", // Important!
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url

      // A client has paid to have custom report names so here we expand this to custom names for their two engaements
      if(params.engagementId == 47){
        link.setAttribute("download",`section_${params.sectionId}_status_report MRA.pdf`)
      }else if(params.engagementId == 45){
        link.setAttribute("download",`section_${params.sectionId}_status_report CO.pdf`)
      }else {
        link.setAttribute("download",`section_${params.sectionId}_status_report.pdf`)
      }
      
      document.body.appendChild(link)
      link.click()
      setDownloadedSectionStatus((prevState) => ({
        ...prevState,
        loading: false,
        downloaded: true,
      }))
    })
    .catch((error) => {
      setDownloadedSectionStatus((prevState) => ({
        ...prevState,
        loading: false,
      }))
      console.log(error)
    })
}

// This series of legacy functions drives the autosave features of the Section/Action Items
function handleSetSectionTitle(
  sectionTitle,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .put(
      `${env.API_BASE_URL}/sections/${actionState.section.id}`,
      { title: sectionTitle },
      { headers: contextValue.getTokens() }
    )
    .then((response) => {
      const newSections = actionState.sections
      newSections.splice(
        actionState.sections.indexOf(
          actionState.sections.find(
            (section) => section.id === actionState.section.id
          )
        ),
        1,
        response.data
      )
      setActionState((prevState) => ({
        ...prevState,
        sections: newSections,
        section: response.data,
      }))
    })
    .catch((error) => {
      console.log(error)
    })
}
function handleSetAssignedArea(
  assignedArea,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .put(
      `${env.API_BASE_URL}/sections/${actionState.section.id}`,
      { assigned_area: assignedArea },
      { headers: contextValue.getTokens() }
    )
    .then(() => {}) // The UI sets itself because its a dropdown, state management handles any refreshes
    .catch((error) => {
      console.log(error)
    })
}
function handleSetDeadline(
  deadline,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .put(
      `${env.API_BASE_URL}/sections/${actionState.section.id}`,
      { deadline: deadline },
      { headers: contextValue.getTokens() }
    )
    .then((response) => {
      setActionState((prevState) => ({
        ...prevState,
        section: response.data,
      }))
    })
    .catch((error) => {
      console.log(error)
    })
}
function handleNewActionItem(
  newActionItems,
  title,
  actionState,
  setActionState,
  contextValue
) {
  const result = actionState.actionItems
  const [removed] = result.splice(
    0,
    1,
    newActionItems.find((actionItem) => actionItem.title === title)
  )
  result.splice(1, 0, removed)
  const newState = []
  result.map((newActionItem, index) => {
    if (newActionItem) {
      newActionItem.position = index + 1
      newState.push(newActionItem)
      axios
        .put(
          `${env.API_BASE_URL}/action_items/${newActionItem.id}`,
          {
            position: index + 1,
          },
          {
            headers: contextValue.getTokens(),
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log(error)
        })
    }
    return null
  })
  setActionState((prevState) => ({
    ...prevState,
    actionItems: newState,
  }))
}
function handleSetResponsibleParty(
  actionItemId,
  actionItemResponsibleParty,
  contextValue
) {
  axios
    .put(
      `${env.API_BASE_URL}/action_items/${actionItemId}`,
      {
        responsible_party: actionItemResponsibleParty,
      },
      {
        headers: contextValue.getTokens(),
      }
    )
    .then((response) => {})
    .catch((error) => {
      console.log(error)
    })
}
function handleSetSignoffAuthority(
  actionItemId,
  actionItemSignoffAuthority,
  contextValue
) {
  axios
    .put(
      `${env.API_BASE_URL}/action_items/${actionItemId}`,
      {
        signoff_authority: actionItemSignoffAuthority,
      },
      {
        headers: contextValue.getTokens(),
      }
    )
    .then((response) => {})
    .catch((error) => {
      console.log(error)
    })
}
function handleSetValidationDate(
  actionItemId,
  validationDate,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .put(
      `${env.API_BASE_URL}/action_items/${actionItemId}`,
      {
        validation_date: validationDate,
      },
      {
        headers: contextValue.getTokens(),
      }
    )
    .then((response) => {
      actionState.actionItems.map((actionItem) => {
        if (actionItem.id === response.data.id) {
          actionItem.validation_date = new Date(Date.parse(response.data.validation_date))
        }
        return []
      })
      setActionState((prevState) => ({
        ...prevState,
        actionItems: actionState.actionItems,
      }))
    })
    .catch((error) => {
      console.log(error)
    })
}
function handleSetActualCompleteDate(
  actionItemId,
  actualCompleteDate,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .put(
      `${env.API_BASE_URL}/action_items/${actionItemId}`,
      {
        actual_complete_date: actualCompleteDate,
      },
      {
        headers: contextValue.getTokens(),
      }
    )
    .then((response) => {
      actionState.actionItems.map((actionItem) => {
        if (actionItem.id === response.data.id) {
          actionItem.actual_complete_date = new Date(Date.parse(response.data.actual_complete_date))
        }
        return []
      })
      setActionState((prevState) => ({
        ...prevState,
        actionItems: actionState.actionItems,
      }))
    })
    .catch((error) => {
      console.log(error)
    })
}
function handleSetTargetCompleteDate(
  actionItemId,
  targetCompleteDate,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .put(
      `${env.API_BASE_URL}/action_items/${actionItemId}`,
      {
        target_complete_date: targetCompleteDate,
      },
      {
        headers: contextValue.getTokens(),
      }
    )
    .then((response) => {
      actionState.actionItems.map((actionItem) => {
        if (actionItem.id === response.data.id) {
          actionItem.target_complete_date = new Date(Date.parse(response.data.target_complete_date))
        }
        return []
      })
      setActionState((prevState) => ({
        ...prevState,
        actionItems: actionState.actionItems,
      }))
    })
    .catch((error) => {
      console.log(error)
    })
}
function handleSetTargetStartDate(
  actionItemId,
  targetStartDate,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .put(
      `${env.API_BASE_URL}/action_items/${actionItemId}`,
      {
        target_start_date: targetStartDate,
      },
      {
        headers: contextValue.getTokens(),
      }
    )
    .then((response) => {
      actionState.actionItems.map((actionItem) => {
        if (actionItem.id === response.data.id) {
          actionItem.target_start_date = new Date(Date.parse(response.data.target_start_date))
        }
        return []
      })
      setActionState((prevState) => ({
        ...prevState,
        actionItems: actionState.actionItems,
      }))
    })
    .catch((error) => {
      console.log(error)
    })
}
function handleSetStatus(
  actionItem,
  status,
  actionState,
  setActionState,
  contextValue
) {
  let progress
  if (status === "Complete") {
    progress = 100
  } else if (status === "Pending") {
    progress = 0
  } else if (status === "Pending Validation") {
    progress = 95
  } else if (status === "Pending Supervisory Review") {
    progress = 100
  } else {
    progress = actionItem.in_progress_percent
  }
  axios
    .put(
      `${env.API_BASE_URL}/action_items/${actionItem.id}`,
      {
        status: status,
        in_progress_percent: progress,
      },
      {
        headers: contextValue.getTokens(),
      }
    )
    .then((response) => {
      actionState.actionItems.map((actionItem) => {
        if (actionItem.id === response.data.id) {
          actionItem.in_progress_percent = progress
          actionItem.status = status
        }
        return []
      })
      setActionState((prevState) => ({
        ...prevState,
        actionItems: actionState.actionItems,
      }))
    })
    .catch((error) => {
      console.log(error)
    })
}
function handleSetTitle(actionItem, title, contextValue) {
  axios
    .put(
      `${env.API_BASE_URL}/action_items/${actionItem}`,
      {
        title: title,
      },
      {
        headers: contextValue.getTokens(),
      }
    )
    .then((response) => {})
    .catch((error) => {
      console.log(error)
    })
}
function handleSetProgressPercent(
  actionItemId,
  inProgressPercent,
  actionState,
  contextValue
) {
  const result = actionState.actionItems.find(({ id }) => id === actionItemId)
  if (result.status === "In Progress" || result.status === "Canceled" || result.status === "On Hold") {
    axios
      .put(
        `${env.API_BASE_URL}/action_items/${actionItemId}`,
        {
          in_progress_percent: inProgressPercent,
        },
        {
          headers: contextValue.getTokens(),
        }
      )
      .then(() => {})
      .catch((error) => {
        console.log(error)
      })
  }
}
function handleNewActionItemCreation(
  title,
  handleClose,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .post(
      `${env.API_BASE_URL}/action_items`,
      {
        title: title,
        section_id: actionState.section.id,
      },
      {
        headers: contextValue.getTokens(),
      }
    )
    .then((response) => {
      const result = actionState.actionItems
      const [removed] = result.splice(
        0,
        1,
        response.data.find((actionItem) => actionItem.title === title)
      )
      result.splice(1, 0, removed)

      const newState = []
      result.map((newActionItem, index) => {
        if (newActionItem) {
          newActionItem.position = result.length - index
          newState.unshift(newActionItem)
          axios
            .put(
              `${env.API_BASE_URL}/action_items/${newActionItem.id}`,
              {
                position: newActionItem.position,
              },
              {
                headers: contextValue.getTokens(),
              }
            )
            .then((response) => {})
            .catch((error) => {
              console.log(error)
            })
        }
        return null
      })
      setActionState((prevState) => ({
        ...prevState,
        actionItems: newState,
      }))
      // Close the modal
      handleClose()
    })
    .catch((error) => {
      console.log(error)
    })
}
function handleActionItemDeletion(
  actionItemId,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .delete(`${env.API_BASE_URL}/action_items/${actionItemId}`, {
      headers: contextValue.getTokens(),
    })
    .then((response) => {
      const result = actionState.actionItems
      result.splice(
        actionState.actionItems.indexOf(
          actionState.actionItems.find(
            (actionItem) => actionItem.id === actionItemId
          )
        ),
        1
      )
      const newState = []
      result.map((newActionItem, index) => {
        newActionItem.position = index + 1
        newState.push(newActionItem)
        axios
          .put(
            `${env.API_BASE_URL}/action_items/${newActionItem.id}`,
            {
              position: index + 1,
            },
            {
              headers: contextValue.getTokens(),
            }
          )
          .then((response) => {})
          .catch((err) => console.log(err))
        return null
      })
      setActionState((prevState) => ({
        ...prevState,
        actionItems: newState,
      }))
    })
    .catch((error) => {
      console.log(error)
    })
}
function handleFileUploadDeletion(
  fileUploadId,
  actionState,
  setActionState,
  contextValue
) {
  axios
    .delete(`${env.API_BASE_URL}/file_uploads/${fileUploadId}`, {
      headers: contextValue.getTokens(),
    })
    .then(() => {
      const currentState = actionState.fileUploads
      const newState = currentState.filter((fileUpload) => {
        return fileUpload.id !== fileUploadId
      })
      setActionState((prevState) => ({
        ...prevState,
        fileUploads: newState,
      }))
    })
    .catch((error) => {
      console.log(error)
    })
}
// This function replaces the current section of context with a new one effectively 'navigating' to a new section page
function jumpToNewSection(
  newSection,
  setActionState,
  setisLoading,
  contextValue,
  history,
  params
) {
  setActionState((prevState) => ({
    ...prevState,
    isLoading: true,
  }))
  axios
    .get(`${env.API_BASE_URL}/sections/${newSection.id}`, {
      headers: contextValue.getTokens(),
    })
    .then((sectionResponse) => {
      setActionState((prevState) => ({
        ...prevState,
        isLoading: false,
        section: sectionResponse.data.section,
        actionItems: sectionResponse.data.action_items,
      }))
      history.push(
        `/clients/${params.clientId}/engagements/${params.engagementId}/sections/${newSection.id}`
      )
    })
    .catch((error) => {
      console.log(error)
    })
}
// This function keeps all of the action items placement in sync allowing drag and drop reordering that persists
function reorderActionItems(state, contextValue, setActionState) {
  const newState = []
  state.items.map((newActionItem, index) => {
    newActionItem.position = index + 1
    newState.push(newActionItem)
    axios
      .put(
        `${env.API_BASE_URL}/action_items/${newActionItem.id}`,
        {
          position: index + 1,
        },
        {
          headers: contextValue.getTokens(),
        }
      )
      .then((response) => {})
      .catch((error) => {
        console.log(error)
      })
    return null
  })
  setActionState((prevState) => ({
    ...prevState,
    actionItems: newState,
  }))
}

export {
  API,
  useSidebar,
  useClients,
  useEngagements,
  useSections,
  useEngagement,
  useSection,
  useAuditTrail,
  CreateRow,
  EditRow,
  DeleteRow,
  handleStatusReport,
  handleSnapshotReport,
  handleSectionStatusReport,
  // Functions driving the autosave features of the Section/Action Items (Section.js)
  handleSetSectionTitle,
  handleSetAssignedArea,
  handleSetDeadline,
  handleNewActionItem,
  handleSetResponsibleParty,
  handleSetSignoffAuthority,
  handleSetValidationDate,
  handleSetActualCompleteDate,
  handleSetTargetCompleteDate,
  handleSetTargetStartDate,
  handleSetStatus,
  handleSetTitle,
  handleSetProgressPercent,
  handleNewActionItemCreation,
  handleActionItemDeletion,
  handleFileUploadDeletion,
  jumpToNewSection,
  reorderActionItems,
}
