import React, { Component } from "react"
import image from "../../assets/images/baseline-check_circle_outline-24px.svg"
import Dropzone from "./Dropzone"
import ProgressBar from "../Chart/Bar/ProgressBar"
import env from "../../Config/environment"
import axios from "axios"
import { AuthContext } from "../../Auth"
import { NotificationManager } from "react-notifications"

// This component accepts a single prop called "uploadParams" which is expected to be in the format of an object containing
// key value pairs that will be sent to the backend as params alongside the file upload

const errorNotification = (error) => {
  return NotificationManager.error(
    error.response.data.message,
    error.response.data.title,
    200000
  )
}

class FileUpload extends Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)
    this.state = {
      files: [],
      returnObjects: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
    }

    this.onFilesAdded = this.onFilesAdded.bind(this)
    this.uploadFiles = this.uploadFiles.bind(this)
    this.sendRequest = this.sendRequest.bind(this)
  }

  // This is the function that fires when the user drops a file on the FileUpload or clicks on it and chooses a file and it
  // will take in an array of files that were just added and add them to the array of files already added displaying all of them
  onFilesAdded(files) {
    this.setState((prevState) => ({
      files: prevState.files.concat(files),
    }))
  }

  // This function fires when the user clicks on the "Upload" button and it will handle the visual state of the component while
  // calling the sendRequest function for each file which is responsible for interfacing with the database
  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true })
    const promises = []
    this.state.files.forEach((file) => {
      promises.push(this.sendRequest(file))
    })
    try {
      // We want to trigger the parent component only after all of the upload files have completed so we await a return response
      await Promise.all(promises)

      this.setState({ successfullUploaded: true, uploading: false })
      this.props.handleFinished(this.state.returnObjects)
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false })
    }
  }

  // This function is called by the uploadFiles function for each uploaded file when the user clicks on the "Upload" button and is
  // responsible for sending the files to the server for long term storage
  async sendRequest(file) {
    const formData = new FormData()
    formData.append("file", file, file.name)

    // This component takes in a single prop called uploadParams that is expected to be in the format of an object containing key value
    // pairs that correspond to the key value pair that you expect your server to receive alongside the uploaded file
    for (const key in this.props.uploadParams) {
      if (this.props.uploadParams.hasOwnProperty(key)) {
        formData.append(key, this.props.uploadParams[key])
      }
    }

    // We want to trigger an action to the parent component letting it know after all of the uploads are finished so we await the function
    return await axios
      .post(`${env.API_BASE_URL}/file_uploads`, formData, {
        headers: this.context.getTokens(),
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
              progressEvent.target.getResponseHeader(
                "x-decompressed-content-length"
              )
          if (totalLength !== null) {
            const copy = { ...this.state.uploadProgress }
            const percentage = Math.round(
              (progressEvent.loaded * 100) / totalLength
            )
            let fileState
            if (percentage === 100) {
              fileState = "done"
            } else {
              fileState = "pending"
            }
            copy[file.name] = {
              state: fileState,
              percentage: percentage,
            }
            this.setState({ uploadProgress: copy })
          }
        },
      })
      .then((response) => {
        const currentState = this.state.returnObjects
        currentState.push(response.data)
        this.setState({ returnObjects: currentState })
      })
      .catch((error) => {
        errorNotification(error)
      })
  }

  render() {
    return (
      <div className="Card">
        <div className="Upload">
          <div className="Content">
            <div>
              <Dropzone
                onFilesAdded={this.onFilesAdded}
                disabled={
                  this.state.uploading || this.state.successfullUploaded
                }
              />
            </div>
            <div className="Files">
              {this.state.files.map((file) => {
                return (
                  <div key={file.name} className="Row">
                    <span className="Filename">{file.name}</span>
                    <div className="ProgressWrapper">
                      <ProgressBar
                        progress={
                          this.state.uploadProgress[file.name]
                            ? this.state.uploadProgress[file.name].percentage
                            : 0
                        }
                      />
                      <img
                        className="CheckIcon"
                        alt="done"
                        src={image}
                        style={{
                          opacity:
                            this.state.uploadProgress[file.name] &&
                            this.state.uploadProgress[file.name].state ===
                              "done"
                              ? 0.5
                              : 0,
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="Actions">
            <button
              disabled={this.state.files.length < 0 || this.state.uploading}
              onClick={this.uploadFiles}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default FileUpload
