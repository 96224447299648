// These helper functions are designed to guide the user on how to input data into our application and must always
// be supported by validations on the server for any security concerns

// This function checks to see if the users input contains only letters or numbers and the plus is us adding in _ and " "
export function isAlphaNumericPlus(str) {
  var code, i, len

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i)
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123) && // lower alpha (a-z)
      !(code === 95) && // _ underscore
      !(code === 32) // single blank space
    ) {
      return false
    }
  }
  return true
}

// This function checks to see if the users input contains only numbers and the plus is us adding in _ and " "
export function isNumericPlus(str) {
  var code, i, len

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i)
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code === 95) && // _ underscore
      !(code === 32) // single blank space
    ) {
      return false
    }
  }
  return true
}
