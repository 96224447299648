import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import FileUpload from './FileUpload'

class FileUploader extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {}

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleFinished = this.handleFinished.bind(this)
  }

  handleClose () {
    this.setState({ show: false })
  }

  handleShow () {
    this.setState({ show: true })
  }

  handleFinished (fileUploads) {
    this.handleClose()
    this.props.handleFilesUploaded(fileUploads)
  }

  render () {
    return (
      <>
        <Button
          variant='primary'
          className='white-green-link-button float-right'
          onClick={this.handleShow}
        >
          Upload File
        </Button>
        <Modal
          className='file-uploader-modal'
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header className='custom-modal-header' closeButton>
            <Modal.Title>Upload Files</Modal.Title>
          </Modal.Header>
          <FileUpload
            uploadParams={{ action_item_id: this.props.actionItem.id }}
            handleFinished={this.handleFinished}
            handleFileUploaded={this.props.handleFileUploaded}
          />
        </Modal>
      </>
    )
  }
}

export default FileUploader
