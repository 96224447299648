import React from "react";
import { AuthContext } from "../Auth";
import AutosaveInput from "../Components/SectionParts/AutosaveInput";
import DeleteResourceModal from "../Components/SectionParts/DeleteResourceModal";
import FileUploader from "../Components/FileUpload/FileUploader";
import { API } from "../Utils/api";
import AutosaveDatePicker from "../Components/SectionParts/AutosaveDatePicker";
import AutosaveDropdown from "../Components/SectionParts/AutosaveDropdown";
import TextEditor from "../Components/SectionParts/TextEditor";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import env from "../Config/environment";
import axios from "axios";
import { NotificationManager } from "react-notifications";

const stringToHTML = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  if (doc.body.getElementsByTagName("p")[0]) {
    return doc.body.getElementsByTagName("p")[0].innerHTML;
  } else {
    return doc.body.innerHTML;
  }
};

const errorNotification = (error) => {
  return NotificationManager.error(
    error.response.data.message,
    error.response.data.title,
    200000
  );
};

class ActionItem extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      fileUploads: [],
      responsibleParty: "",
      open: false,
    };

    this.autoSaveSignoffAuthority = this.autoSaveSignoffAuthority.bind(this);
    this.autoSaveResponsibleParty = this.autoSaveResponsibleParty.bind(this);
    this.autoSaveProgressPercent = this.autoSaveProgressPercent.bind(this);
    this.autoSaveTargetCompleteDate = this.autoSaveTargetCompleteDate.bind(
      this
    );
    this.autoSaveTargetStartDate = this.autoSaveTargetStartDate.bind(this);
    this.autoSaveActualCompleteDate = this.autoSaveActualCompleteDate.bind(
      this
    );
    this.autoSaveValidationDate = this.autoSaveValidationDate.bind(this);
    this.autoSaveStatus = this.autoSaveStatus.bind(this);
    this.autoSaveTitle = this.autoSaveTitle.bind(this);
    this.filteredUploads = this.filteredUploads.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  autoSaveResponsibleParty(newActionItemResponsibleParty) {
    this.props.responsibleParty(
      this.props.actionItem.id,
      newActionItemResponsibleParty,
      this.props.contextValue
    );
  }

  autoSaveSignoffAuthority(newActionItemSignoffAuthority) {
    this.props.signoffAuthority(
      this.props.actionItem.id,
      newActionItemSignoffAuthority,
      this.props.contextValue
    );
  }

  autoSaveProgressPercent(newActionItemInProgressPercent) {
    this.props.progressPercent(
      this.props.actionItem.id,
      newActionItemInProgressPercent,
      this.props.actionState,
      this.props.contextValue
    );
  }

  autoSaveTitle(newActionItemTitle) {
    this.props.actionItemTitle(
      this.props.actionItem.id, 
      newActionItemTitle, 
      this.props.contextValue
     );
  }

  autoSaveValidationDate(newActionItemValidationDate) {
    this.props.validationDate(
      this.props.actionItem.id,
      newActionItemValidationDate,
      this.props.actionState,
      this.props.setActionState,
      this.props.contextValue
    );
  }

  autoSaveActualCompleteDate(newActionItemActualCompleteDate) {
    this.props.actualCompleteDate(
      this.props.actionItem.id,
      newActionItemActualCompleteDate,
      this.props.actionState,
      this.props.setActionState,
      this.props.contextValue
    );
  }

  autoSaveTargetCompleteDate(newActionItemTargetCompleteDate) {
    this.props.targetCompleteDate(
      this.props.actionItem.id,
      newActionItemTargetCompleteDate,
      this.props.actionState,
      this.props.setActionState,
      this.props.contextValue
    );
  }

  autoSaveTargetStartDate(newActionItemTargetStartDate) {
    this.props.targetStartDate(
      this.props.actionItem.id,
      newActionItemTargetStartDate,
      this.props.actionState,
      this.props.setActionState,
      this.props.contextValue
    );
  }

  autoSaveStatus(newActionItemStatus) {
    this.props.status(
      this.props.actionItem,
      newActionItemStatus,
      this.props.actionState,
      this.props.setActionState,
      this.props.contextValue
    );
  }

  filteredUploads() {
    const innerThis = this;
    let parentUploads = this.props.fileUploads;
    return parentUploads.filter(function (upload) {
      return upload.action_item_id === innerThis.props.actionItem.id;
    });
  }

  handleDownloadRequest(fileUpload) {
    const method = "GET";
    const url = `${env.API_BASE_URL}/download_file/${fileUpload.id}`;
    axios
      .request({
        url,
        method,
        responseType: "blob", //important
        headers: this.context.getTokens(),
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileUpload.original_filename); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        errorNotification(error);
      });
  }

  handleViewRequest(fileUpload) {
    API(`view_file/${fileUpload.id}`, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
      headers: this.context.getTokens(),
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        errorNotification(error);
      });
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  componentDidMount() {
    this.setState({
      responsibleParty: this.props.actionItem
        .responsible_party_client_user_email,
    });
  }

  render() {
    const signoffAuthority = () => {
      // 11, ACBB
      if (this.props.clientId === "11") {
        return "Validating Party";
      } else {
        return "Signoff Authority";
      }
    };
    const progressUpdateOrmanagementResponse = () => {
      // 20, Nodus Bank
      if (this.props.clientId === "20") {
        return "Management Response";
      } else {
        return "Progress Update";
      }
    };
    const referenceOrmanagementRemediationAction = () => {
      // 20, Nodus Bank
      if (this.props.clientId === "20") {
        return "Management Remediation Action";
      } else {
        return "Reference";
      }
    };
    const deliverables = () => {
      // 20, Nodus Bank
      if (this.props.clientId === "20") {
        return (
          <Grid item xs={12}>
            <Typography className="card-green">Deliverables</Typography>
            <TextEditor
              actionItem={this.props.actionItem}
              stuff={this.props.actionItem.deliverables}
              title={false}
              editedField="deliverables"
              isSection={false}
            />
          </Grid>
        );
      } else {
        return;
      }
    };
    // Jason requested that signoff authority not display for 2 specific engagements.
    const signOffAuthorityBlock = () => {
      console.log("hi mom")
      console.log(this.props.engagementId)
      console.log(this.props.clientId)
      console.log("hi dad")
      // 20, Nodus Bank
      if (this.props.engagementId !== "49" && this.props.engagementId !== "50") {
        return (
          <Grid item xs={6}>
            <Typography className="card-green">
              {signoffAuthority()}
            </Typography>

            <AutosaveInput
              saveInputValue={this.autoSaveSignoffAuthority}
              defaultValue={this.props.actionItem.signoff_authority}
            />
          </Grid>          
        );
      } else {
        return(
          <Grid item xs={6}>

          </Grid>
        );
      }
    };
    // Jason requested that validation date not display for 2 specific engagements.
    const validationDateBlock = () => {
      // 20, Nodus Bank
      if (this.props.engagementId !== "49" && this.props.engagementId !== "50") {
        return (
          <Grid item xs={6}>
            <AutosaveDatePicker
              startDate={this.props.actionItem.validation_date}
              saveInputValue={this.autoSaveValidationDate}
              title={"Validation Date"}
              id={this.props.actionItem.id + "four"}
            />
          </Grid>        
        );
      } else {
        return(
          <Grid item xs={6}>

          </Grid>
        );
      }
    };
    // Jason requested specific drop down list for an individual client - 3 specific engagements, it has others
    const selections = () => {
      if (this.props.engaementId == 49 || this.props.engagementId == 50 || this.props.engagementId == 53) {
        return (
          <Grid item xs={6}>
            <Typography className="card-green">Status</Typography>
            <AutosaveDropdown
              saveInputValue={this.autoSaveStatus}
              defaultValue={this.props.actionItem.status}
              selections={["Pending", "In Progress", "On Hold", "Canceled", "Complete"]}
            />
          </Grid>
        );
      } else {
        return (
          <Grid item xs={6}>
            <Typography className="card-green">Status</Typography>
            <AutosaveDropdown
              saveInputValue={this.autoSaveStatus}
              defaultValue={this.props.actionItem.status}
              selections={["Pending", "In Progress", "Pending Validation", "Pending Supervisory Review", "Complete"]}
            />
          </Grid>
        )
      }
    }


    

    return (
      <div style={{ width: "100%" }}>
        <Card>
          <CardHeader
            className="card-green"
            avatar={<Avatar>{this.props.actionItem.position}</Avatar>}
            title={
              <Typography className="card-green">
                {stringToHTML(this.props.actionItem.title)}
              </Typography>
            }
            // subheader={this.props.actionItem.status}
            action={
              <CardActions>
                <DeleteResourceModal
                  resource={this.props.actionItem}
                  handleActionItemDeletion={this.props.handleActionItemDeletion}
                  actionState={this.props.actionState}
                  setActionState={this.props.setActionState}
                  contextValue={this.props.contextValue}
                  resourceType="Item"
                  classNames="white-green-link-button left-button-bumper float-right"
                />
              </CardActions>
            }
          />

          <CardContent>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="overline" display="block" gutterBottom>
                  {"Click to Expand"}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography className="card-green">{"Title"}</Typography>
                    <AutosaveInput
                      saveInputValue={this.autoSaveTitle}
                      defaultValue={this.props.actionItem.title}
                    />
                    {/*<TextEditor*/}
                    {/*  actionItem={this.props.actionItem}*/}
                    {/*  stuff={this.props.actionItem.title}*/}
                    {/*  title={false}*/}
                    {/*  editedField="title"*/}
                    {/*  isSection={false}*/}
                    {/*/>*/}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="card-green">
                      {"Responsible Party"}
                    </Typography>
                    <AutosaveInput
                      saveInputValue={this.autoSaveResponsibleParty}
                      defaultValue={this.props.actionItem.responsible_party}
                    />
                  </Grid>

                  {signOffAuthorityBlock()}

                  <Grid item xs={6}>
                    <AutosaveDatePicker
                      startDate={this.props.actionItem.target_start_date}
                      saveInputValue={this.autoSaveTargetStartDate}
                      title={"Target Start Date"}
                      id={this.props.actionItem.id + "one"}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutosaveDatePicker
                      startDate={this.props.actionItem.target_complete_date}
                      saveInputValue={this.autoSaveTargetCompleteDate}
                      title={"Target Complete Date"}
                      id={this.props.actionItem.id + "two"}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutosaveDatePicker
                      startDate={this.props.actionItem.actual_complete_date}
                      saveInputValue={this.autoSaveActualCompleteDate}
                      title={"Actual Complete Date"}
                      id={this.props.actionItem.id + "three"}
                    />
                  </Grid>

                  {validationDateBlock()}

                  <Grid item xs={6}>
                    <Typography className="card-green">Progress</Typography>
                    <AutosaveInput
                      saveInputValue={this.autoSaveProgressPercent}
                      defaultValue={this.props.actionItem.in_progress_percent}
                    />
                  </Grid>
                  {console.log(this.props.engagementId)}
                  
                  {selections()}

                  <Grid item xs={12}>
                    <Typography className="card-green">Description of Action Plan</Typography>
                    <TextEditor
                      actionItem={this.props.actionItem}
                      stuff={this.props.actionItem.action_item_text}
                      title={false}
                      editedField="action_item_text"
                      isSection={false}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className="card-green">
                      {progressUpdateOrmanagementResponse()}
                    </Typography>
                    <TextEditor
                      actionItem={this.props.actionItem}
                      stuff={this.props.actionItem.progress_update_text}
                      title={false}
                      editedField="progress_update_text"
                      isSection={false}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography className="card-green">
                      {referenceOrmanagementRemediationAction()}
                    </Typography>
                    <TextEditor
                      actionItem={this.props.actionItem}
                      stuff={this.props.actionItem.reference}
                      title={false}
                      editedField="reference"
                      isSection={false}
                    />
                  </Grid>

                  {deliverables()}

                  <Grid item xs={12}>
                    <Typography className="card-green">File Uploads</Typography>
                    <FileUploader
                      actionItem={this.props.actionItem}
                      handleFilesUploaded={this.props.handleFilesUploaded}
                    />
                    <Grid item>
                      {this.filteredUploads().map((fileUpload, index) => {
                        return (
                          <Grid
                            container
                            direction="row"
                            justify="space-evenly"
                            alignItems="center"
                            spacing={4}
                            key={fileUpload.id}
                          >
                            <Grid item xs={6}>
                              {index + 1}. {fileUpload.original_filename}
                            </Grid>
                            <Grid item xs>
                              <DeleteResourceModal
                                resource={fileUpload}
                                handleActionItemDeletion={this.props.handleFileUploadDeletion}
                                actionState={this.props.actionState}
                                setActionState={this.props.setActionState}
                                contextValue={this.props.contextValue}
                                resourceType="File Upload"
                                classNames="blue-link-button left-button-bumper"
                              />
                            </Grid>
                            <Grid item xs>
                              <Button
                                className="blue-link-button left-button-bumper"
                                onClick={() =>
                                  this.handleDownloadRequest(fileUpload)
                                }
                              >
                                Download
                              </Button>
                            </Grid>
                            <Grid item xs>
                              <Button
                                className="blue-link-button"
                                onClick={() =>
                                  this.handleViewRequest(fileUpload)
                                }
                              >
                                View
                              </Button>
                            </Grid>
                            <Divider />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default ActionItem;
