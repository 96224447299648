// We use the Material-UI React library as our front end design framework
import { createMuiTheme } from "@material-ui/core/styles"
// There are two hooks 'makeStyles' for working with React functions and 'withStyles' for working with React classes
import makeStyles from "@material-ui/core/styles/makeStyles"
// Some classes change color depending on the theme so we import the color instead of hardcoding #357a38 for example
import green from '@material-ui/core/colors/green';
import { colors } from './colors'

// This is a global variale because we use it across several styles to make our left hand nav bar work
const drawerWidth = 290

//////////////////////////////////////////////////////////////////
// IMPORTANT NOTE!!                                             //
//////////////////////////////////////////////////////////////////
// This is an example of how themes were being exported. This works 100% fine in development. In production this
// was causing our custom classes to be injected in the middle of the material-ui library classes which was causing
// all kinds of styling issues. see https://github.com/mui-org/material-ui/issues/15214 for details
//export const fileTagsTableStyles = makeStyles((theme) => ({
// root: {
//   display: "flex",
// },
//}))
// const classes = fileTagsTableStyles()
// This is an example of how it needs to be done to ensure that our styles in production are loading identical to dev
// export const fileTagsTableStyles = () => {
//   return makeStyles((theme) => ({
//     root: {
//       display: "flex",
//     },
//   }))
// }
// const classes = fileTagsTableStyles()()  !Note the second function call

// The theme specifies the color of the components, darkness of the surfaces, level of shadow, appropriate
// opacity of ink elements, etc. This lets you apply a consistent tone to your app. There are light and dark
// themes available but MUI uses light by default. The theme has 6 high level areas, not all of which have
// been explored by our team to date
// https://material-ui.com/customization/theming/
// 1) Palette
// 2) Typography
// 3) Spacing
// 4) Breakpoints
// 5) z-index
// 6) Globals
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.CHARCOAL_GRAY.hex,
      dark: colors.CHARCOAL_GRAY.hex,
      light: colors.CHARCOAL_GRAY.hex,
    },
    secondary: {
      main: colors.MIDNIGHT_BLUE.hex,
      dark: colors.MIDNIGHT_BLUE.hex,
      light: "",
    },
  },
  paper: {
    padding: 20,
    margin: 20,
    textAlign: "center",
  },
})

//////////////////////////////////////////////////////////////////
// These contain the styles for each of our primary "web pages" //
//////////////////////////////////////////////////////////////////
// Page 0) App.js
export const appStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    height: "100vh",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentWrapper: {
    overflow: "auto",
    position: "fixed",
    top: theme.spacing(3),
    height: "calc(100% - 64px)", // Subtract width of header
    // backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  customizeToolbar: {
    minHeight: 36,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  link: {
    display: "flex",
  },
}))
// Page 1) Home.js
export const homeStyles = () => {
  return makeStyles((theme) => ({
    root: {},
  }))
}
// Page 2) Clients.js
export const clientsStyles = () => {
  return makeStyles((theme) => ({
    root: {},
  }))
}
// Page 3) Engagements.js
export const engagementsStyles = () => {
  return makeStyles((theme) => ({
    leftSideNavigationButton: {
      float: "left",
    },
  }))
}
// Page 4) Sections.js
export const sectionsStyles = () => {
  return makeStyles((theme) => ({

    hello: {
      backgroundColor: "red"
    },


    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      textAlign: "center",
      backgroundColor: colors.MIDNIGHT_BLUE.rgb,
      color: "white",
      flex: "1 1 100%",
    },
    tableHeader: {
      textAlign: "center",
      fontWeight: "bold",
    },
    table: {
      minWidth: 450,
    },
    titleColumn: {
      width: "300px",
    },
    responsiblePartyColumn: {
      width: "165px",
    },
    dueDateColumn: {
      width: "85px",
    },
    tableRow: {
      fontSize: "18px",
      transition: "all .5s ease",
      paddingTop: "10px",
      marginTop: "10px",
      "&:hover": {
        cursor: "pointer",
        boxShadow: "0 3px 9px rgba(0, 0, 0, .2)",
        marginTop: "20px",
        paddingTop: "20px",
      },
      "&:active": {
        cursor: "pointer",
        boxShadow: "0 1px 3px rgba(0, 0, 0, .2)",
      },
    },
  }))
}
// Page 5) AuditTrail.js
export const auditTrailStyles = () => {
  return makeStyles((theme) => ({
    leftSideNavigationButton: {
      float: "left",
    },
    innerRowBody: {
      marginBottom: "0px",
      color: 'red'
    }
  }))
}
// Page 6) Section.js
export const sectionStyles = () => {
  return makeStyles((theme) => ({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    },
    paper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2)
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    button: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1)
    }
  }))
}




export const sidebarStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  header: {
    fontWeight: 900,
    minWidth: 0,
    fontSize: 18,
  },
  grow: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  logo: {
    maxWidth: 240,
  },
  trakLogo: {
    maxWidth: 110,
  },
  trakMenu: {
    marginRight: 160,
  },
  drawerPaper: {
    position: "fixed",
    width: drawerWidth,
    borderRadius: 0,
    borderTop: "none",
    borderBottom: "none",
    top: theme.spacing(8), // push content down to fix scrollbar position
    height: `calc(100% - ${theme.spacing(8)}px)`, // subtract appbar height
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerContent: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(1),
    color: "lightgrey",
  },
  settingsContainer: {
    marginTop: "auto",
    paddingBottom: "0",
    position: "fixed",
    width: drawerWidth,
    bottom: 0,
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    height: "100vh",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  hide: {
    display: "none",
  },
}))




//////////////////////////////////////////////////////////////////
// These contain the styles for each of our components          //
//////////////////////////////////////////////////////////////////
// components/AnimatedDownloadButton/AnimatedDownloadButton.js
export const animatedDownloadButtonStyles = () => {
  return makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }))
}
// components/BackToTop/BackToTop.js
export const backToTopStyles = () => {
  return makeStyles((theme) => ({
    backToTopRoot: {
      position: "fixed",
      bottom: "16px",
      right: "16px",
    },
  }))
}
// components/Loading/LoadingRing.js
export const loadingRingStyles = () => {
  return makeStyles((theme) => ({
    ldsDualRing: {
      position: "sticky",
      display: "inline-grid",
      top: "50%",
      width: "150px",
      height: "150px",
    },
    ldsDualRingAfter: {
      content: " ",
      display: "block",
      width: "64px",
      height: "64px",
      margin: "8px",
      borderRadius: "50%",
      border: "6px solid #000",
      animation: "$ldsDualRing 1.2s linear infinite", // make sure you preface animation with $ or it wont work with makeStyles
    },
    loadingContainer: {
      marginTop: "40px",
      marginBottom: "40px",
    },
    "@keyframes ldsDualRing": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  }))
}
// components/SectionParts/ActionItemSummary.js
export const actionItemSummaryStyles = () => {
  return makeStyles((theme) => ({
    root: {
      
    },
    title: {
      fontWeight: 'bold',
    },
    titleCenter: {
      fontWeight: 'bold',
      textAlign: 'center'
    }
  }))
}
// components/StatusCount/StatusCount.js
export const statusCountStyles = () => {
  return makeStyles((theme) => ({
    root: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      fontWeight: 500,
      fontSize: 14,
      color: theme.palette.text.hint
    },
    value: {
      fontWeight: 'bold'
    },
    avatar: {
      // width: 48,
      // height: 48,
      transform: 'translateY(50%)',
      '& > img': {
        margin: 0,
        backgroundColor: theme.palette.common.white
      }
    },
    divider: {
      backgroundColor: theme.palette.grey[200],
      marginBottom: 24 - 1 // minus 1 due to divider height
    },
    extra: {
      textTransform: 'uppercase',
      fontSize: 14,
      color: "black",
      letterSpacing: '1px'
    }
  }))
}
// components/TopNavWithSideDrawer/ClientListItem.js
export const clientListStyles = () => {
  return makeStyles((theme) => ({
    listItemIcon: {
      minWidth: "30px;",
    },
    header: {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    subHeader: {
      marginLeft: "15px",
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "36px",
      textDecoration: "underline",
    },
    manageResourceButton: {
      marginLeft: "5px",
      marginTop: "15px",
      display: "inline-block",
    },
  }))
}
// components/TopNavWithSideDrawer/EngagementListItem.js
export const engagementListStyles = () => {
  return makeStyles((theme) => ({
    listItemIcon: {
      minWidth: "30px;",
    },
    subHeaderItem: {
      marginLeft: "15px",
      paddingTop: "0px",
      paddingBottom: "0px",
      maxWidth: "250px",
    },
    subHeader: {
      marginLeft: "30px",
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "36px",
      textDecoration: "underline",
    },
    thirdLevel: {
      marginLeft: "30px",
      paddingTop: "0px",
      paddingBottom: "0px",
      maxWidth: "235px",
    },
  }))  
}
// components/ProgressBar/LinearProgressWithLabel.js
export const linearProgressWithLabelStyles = () => {
  return makeStyles((theme) => ({
    LinearProgress: {
      "& .MuiLinearProgress-bar": {
        backgroundColor: "rgb(93, 135, 161)",
        height: "100%",
        margin: "0",
        borderRadius: "5px",
        borderColor: colors.BORDER_GRAY.hex,
        borderWidth: "1px",
      },
    },    
  }))
}
// components/TopNavWithSideDrawer/TopNavWithSideDrawer.js
export const topNavWithSideDrawerStyles = () => {
  return makeStyles((theme) => ({
    root: {
      display: "flex",
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
    grow: {
      flexGrow: 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    logo: {
      maxWidth: 240,
    },
    scrubLogo: {
      maxWidth: 115,
    },
    scrubMenu: {
      marginRight: 160,
    },
    drawerPaper: {
      position: "fixed",
      width: drawerWidth,
      borderRadius: 0,
      borderTop: "none",
      borderBottom: "none",
      top: theme.spacing(8), // push content down to fix scrollbar position
      height: `calc(100% - ${theme.spacing(8)}px)`, // subtract appbar height
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    button: {
      margin: theme.spacing(1),
      color: "lightgrey",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    sideBarList: {
      width: "100%",
      maxWidth: 360,
    },
    sidebarSubHeader: {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: "36px",
      textDecoration: "underline",
    },
    manageResourceButton: {
      marginLeft: "45px",
      marginTop: "15px",
      display: "inline-block",
    },
  }))
}