// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from 'react'
// We load our application css by only bringing in the styles needed for the current page
import { statusCountStyles } from "../../Config/theme"
// We use the Material-UI React library as our front end design framework
import { Box, Typography } from '@material-ui/core'

// This component tracks the three possible types of action items: Complete, In progress, Pending, and presents a nice display of 
// the current meta status of the project
export default function StatusCount(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = statusCountStyles()()

  // This function displays the first level in our sidebar nav tree containing each unique client
  return (
    <Box className={classes.root} display='flex'>
      <Typography
        variant='h6'
        color='primary'
        className={classes.value}
      >
        {props.value}
      </Typography>
      <Typography className={classes.extra}>
        {props.label}
      </Typography>
    </Box>
  )
}