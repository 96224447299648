import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './Main/App'
import { AuthProvider, PrivateRoute } from './Auth'
import './assets/styles/master.scss'
import * as serviceWorker from './Utils/serviceWorker'
import env from './Config/environment'

const WrappedApp = () => (
  <Router basename={env.ROUTER_BASE_NAME}>
    <AuthProvider>
      <PrivateRoute to='/' component={App} />
    </AuthProvider>
  </Router>
)

ReactDOM.render(<WrappedApp />, document.getElementById('root'))
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
