import React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ActionItem from "../../Main/ActionItem"

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  border: "black",
  padding: "8px",
  flexGrow: "1",
  transition: "background-color 0.2s ease",
  minHeight: "100px",
})

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: "16px",
  margin: `0 0 8px 0`,
  background: isDragging ? "lightgreen" : "rgb(93,135,161,0.5)",
  ...draggableStyle,
})

class SingleColumn extends React.Component {
  onDragEnd = (result) => {
    let contextValue = this.props.contextValue
    let setActionState = this.props.setActionState
    if (!result.destination) {
      return
    }

    const items = reorder(
      this.props.actionItems,
      result.source.index,
      result.destination.index
    )

    this.props.reorderActionItems({items,}, contextValue, setActionState)
  }

  render() {
    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragUpdate={this.onDragUpdate}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.props.actionItems.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ActionItem
                        key={item.id}
                        clientId={this.props.clientId}
                        engagementId={this.props.engagementId}
                        contextValue={this.props.contextValue}
                        actionState={this.props.actionState}
                        setActionState={this.props.setActionState}


                        
                        actionItem={item}
                        fileUploads={this.props.fileUploads}
                        handleActionItemDeletion={
                          this.props.handleActionItemDeletion
                        }
                        actionItemTitle={this.props.actionItemTitle}
                        responsibleParty={this.props.responsibleParty}
                        validationDate={this.props.validationDate}
                        actualCompleteDate={this.props.actualCompleteDate}
                        targetStartDate={this.props.targetStartDate}
                        targetCompleteDate={this.props.targetCompleteDate}
                        signoffAuthority={this.props.signoffAuthority}
                        progressPercent={this.props.progressPercent}
                        status={this.props.status}
                        handleFilesUploaded={this.props.handleFilesUploaded}
                        handleFileUploadDeletion={
                          this.props.handleFileUploadDeletion
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

export default SingleColumn
