// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useState, useContext } from "react"
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link, useParams } from "react-router-dom"
// We use Auth0 as our current authenication and authorization solution
import { AuthContext } from "../../Auth"
// All interactions with the remote server are driven from this file
import {
  useSections,
  useEngagement,
  handleStatusReport,
  handleSnapshotReport,
} from "../../Utils/api"
// We use the Material-UI React library as our front end design framework
import {
  Box,
  Button,
  CardContent,
  colors,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
// We load our application css by only bringing in the styles needed for the current page
import { sectionsStyles } from "../../Config/theme"
// Prevents rendering of the application and shows a spinning circle while the application is loading
import LoadingScreen from "../../Components/Loading/LoadingRing"
// We use Material-Table for CRUD operations on application resources
import MaterialTable from "material-table"
import DataTableIcons from "../../Components/Icons/DataTableIcons"
import { CreateRow, DeleteRow, EditRow } from "../../Utils/api"
// The following are HTML/CSS components that are present on this page
import AnimatedDownloadButton from "../../Components/AnimatedDownloadButton/AnimatedDownloadButton"
import StatusCount from "../../Components/StatusCount/StatusCount"
import ActionItemSummary from "../../Components/SectionsParts/ActionItemSummary"
import LinearProgressWithLabel from "../../Components/ProgressBar/LinearProgressWithLabel"
// TODO: Oriana has been assigned to rebuild this without the small unmaintained dependency 8/27/2021
import HealthMeter from "../../Components/Chart/HealthMeter"
import { colors as colorThemes } from "../../Config/colors"
//changed import name to colorThemes due to naming conflict

import { useSectionsState } from "./hooks"

// The core function for the Sections "web page"
export default function Sections(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = sectionsStyles()()
  // Access the resource ids from the URL bar via the useParams hook
  const params = useParams()

  const {
    loading: [isLoading, setIsLoading],
    engagement: [engagement, setEngagement],
    downloadedStatus: [downloadedStatus, setDownloadedStatus],
    downloadedSnapshot: [downloadedSnapshot, setDownloadedSnapshot],
    gridData: [gridData, setGridData],
  } = useSectionsState({ params })

  // Auth context allows us to access our headers and authenicate with the API when calling handleSubmitSchemaMatch
  const contextValue = useContext(AuthContext)
  // Material-Table options
  const options = {
    headerStyle: {
      backgroundColor: colorThemes.MIDNIGHT_BLUE.hex,
      color: "white",
    },
    cellStyle: {
      wordBreak: "break-all",
    },
    rowStyle: (rowData) => ({
      backgroundColor:
        rowData.status === "Complete"
          ? "rgba(120,255,95,0.2)"
          : rowData.status === "In Progress"
          ? "rgba(255,246,25,0.2)"
          : rowData.status === "Pending"
          ? "rgba(255,102,102,0.2)"
          : "#FFF",
    }),
    grouping: true,
    sorting: true,
    rowHover: true,
    downloadOptions: { filename: "Sections.csv", separator: "," },
    actionsColumnIndex: -1,
    filterType: "dropdown",
    responsive: "stacked",
    selectableRows: false,
    pageSizeOptions: [10, 15, 20],
    pageSize: 10,
    padding: "dense",
    exportButton: true,
    addRowPosition: "first",
  }
  // Material-Table names and labels overrides
  const localization = {
    pagination: {
      labelDisplayedRows: "{from}-{to} of {count}",
    },
    toolbar: {
      nRowsSelected: "{0} row(s) selected",
    },
    header: {
      actions: "Admin Actions",
    },
    body: {
      emptyDataSourceMessage: "No Sections to display",
      filterRow: {
        filterTooltip: "Filter",
      },
    },
  }
  // Material-Table CRUD communication with the API
  const onRowDelete = DeleteRow(
    gridData,
    setGridData,
    "sections",
    params,
    props
  )
  const onRowUpdate = EditRow(gridData, setGridData, "sections", params, props)
  const onRowAdd = CreateRow(gridData, setGridData, "sections", params, props)
  // Material-Table CRUD actions configuration
  const editable = {
    isEditable: (rowData) => true,
    isDeletable: (rowData) => true,
    onRowUpdate: onRowUpdate,
    onRowDelete: onRowDelete,
    onRowAdd: onRowAdd,
  }
  // Material-Table additional component
  const detailPanel = [
    {
      tooltip: "Expand Section Details",
      render: (rowData) => (
        <ActionItemSummary
          actionItems={gridData.actionItems}
          sectionId={rowData.id}
        />
      ),
    },
  ]
  // Helper method that sorts the action items by status into buckets containing a count for each status
  const statusCount = gridData.data.reduce((categoryCount, item) => {
    categoryCount[item.status] = categoryCount[item.status] + 1 || 1
    return categoryCount
  }, {})
  // Static data for the Top Five Due section of action items
  const headersTopFiveDue = [
    {
      label: "Title",
    },
    {
      label: "Responsible Party",
    },
    {
      label: "Due Date",
    },
  ]
  // Helper function used to construct the body of the Top Five Due section of action items
  const topFiveDue = () => {
    function formatDate(date) {
      const d = new Date(date)
      let month = "" + (d.getMonth() + 1)
      let day = "" + d.getUTCDate()
      let year = d.getFullYear()

      if (isNaN(month)) {
        return new Date()
      } else {
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        return [month, day, year].join("/")
      }
    }

    if (gridData.actionItems !== undefined) {
      let arrayToSort = []

      gridData.actionItems.forEach((actionItem) => {
        let relevantDate = Date.parse(actionItem.target_complete_date)

        if (
          actionItem.target_complete_date !== null &&
          actionItem.actual_complete_date === null
        ) {
          arrayToSort.push(actionItem)
        }
      })

      let sortedArray = arrayToSort.sort((a, b) =>
        a.target_complete_date.localeCompare(b.target_complete_date)
      )

      let topFiveValues = sortedArray.slice(0, 5)

      let returnValue = []

      topFiveValues.forEach((actionItem) => {
        if (actionItem.target_complete_date !== null) {
          returnValue.push({
            section_id: actionItem.section_id,
            title: actionItem.title,
            responsibleParty: actionItem.responsible_party,
            dueDate: formatDate(actionItem.target_complete_date),
          })
        }
      })

      return returnValue
    } else {
      return []
    }
  }
  // Helper function for getting the correct progress percent or returning 0 if there is no progress percent yet
  const getProgress = (arr) => {
    return arr.reduce((acc, val) => {
      return acc + (val.in_progress_percent || 0)
    }, 0)
  }

  // The last thing we do after any other setup code and before rendering for the user is reach out to our API
  // for the persistent data that drives this "web page"
  useSections(params, setGridData, setIsLoading)
  useEngagement(params, setEngagement)

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  // This web page will display a loading circle when isLoading is set to true, otherwise it will display the actual content
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Consumer>
      {({ auth }) => (
        <>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item>
              <Link
                to={{
                  pathname: `/clients/${params.clientId}/engagements/`,
                  state: {
                    clientId: params.clientId,
                  },
                }}
              >
                <Button
                  title="Back to Engagements"
                  className="file-uploads-engagements-button"
                >
                  <ArrowBackIcon />
                  {"Back to Engagements"}
                </Button>
              </Link>
            </Grid>

            <Grid item>
              <Link
                to={{
                  pathname: `/clients/${params.clientId}/engagements/${params.engagementId}/sections/audit_trail`,
                  state: {
                    clientId: params.clientId,
                    engagementId:params.engagementId
                  },
                }}
              >
                <Button
                  title="View Audit Trail"
                  className="file-uploads-engagements-button"
                >
                  {"View Audit Trail"}
                  <ArrowForwardIcon />
                </Button>
              </Link>
            </Grid>

            <Grid item xs={12}>
              <div style={{ padding: "0px 10px" }}>
                <CardContent>
                  <Box display="flex">
                    <Box bgcolor="lightgreen" p={2} flex="auto">
                      <StatusCount
                        label="Complete"
                        value={parseFloat(statusCount.Complete) || 0}
                      />
                    </Box>
                    <Box bgcolor="khaki" p={2} flex="auto">
                      <StatusCount
                        label="In Progress"
                        value={parseFloat(statusCount["In Progress"]) || 0}
                      />
                    </Box>
                    <Box bgcolor="lightcoral" p={2} flex="auto">
                      <StatusCount
                        label="Pending"
                        value={parseFloat(statusCount.Pending) || 0}
                      />
                    </Box>
                  </Box>

                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <HealthMeter
                        progressPercent={
                          100 *
                            (getProgress(gridData.data) /
                              (gridData.data.length * 100)) || 0
                        }
                      />
                    </Grid>

                    <Grid item>
                      <TableContainer component={Paper}>
                        <Typography className={classes.title} id="tableTitle">
                          Top Five Action Items by Due Date
                        </Typography>
                        <Table size="small" className={classes.table}>
                          <TableHead>
                            <TableRow>
                              {headersTopFiveDue.map(({ label }, i) => (
                                <TableCell
                                  key={i}
                                  className={classes.tableHeader}
                                >
                                  {label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {topFiveDue().map(
                              (
                                {
                                  section_id,
                                  title,
                                  responsibleParty,
                                  dueDate,
                                },
                                i
                              ) => (
                                <TableRow key={i} className={classes.tableRow}>
                                  <TableCell
                                    padding="default"
                                    className={classes.titleColumn}
                                  >
                                    <Link
                                      to={{
                                        pathname: `/clients/${params.clientId}/engagements/${params.engagementId}/sections/${section_id}`,
                                        state: {
                                          sectionId: section_id,
                                          engagementId: params.engagementId,
                                          clientId: params.clientId,
                                        },
                                      }}
                                    >
                                      {title}
                                    </Link>
                                  </TableCell>
                                  <TableCell
                                    padding="default"
                                    className={classes.responsiblePartyColumn}
                                  >
                                    {responsibleParty}
                                  </TableCell>
                                  <TableCell
                                    padding="default"
                                    className={classes.dueDateColumn}
                                  >
                                    {dueDate}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <AnimatedDownloadButton
                        onClick={() =>
                          handleStatusReport(
                            setDownloadedStatus,
                            params,
                            contextValue
                          )
                        }
                        loading={downloadedStatus.loading}
                        success={downloadedStatus.downloaded}
                        fab={false}
                        buttonName="Download Status Report"
                      />
                    </Grid>

                    <Grid item>
                      <AnimatedDownloadButton
                        onClick={() =>
                          handleSnapshotReport(
                            setDownloadedSnapshot,
                            params,
                            contextValue
                          )
                        }
                        loading={downloadedSnapshot.loading}
                        success={downloadedSnapshot.downloaded}
                        fab={false}
                        buttonName="Download Snapshot Report"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  isLoading={isLoading}
                  data={gridData.data}
                  icons={DataTableIcons}
                  title={engagement.name}
                  columns={gridData.columns}
                  options={options}
                  detailPanel={detailPanel}
                  localization={localization}
                  editable={editable}
                />
              </div>

              {/*{auth.user.admin ? (*/}
              {/*  <ExpansionPanel>*/}
              {/*    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>*/}
              {/*      <Typography>Show AuditTrail</Typography>*/}
              {/*    </ExpansionPanelSummary>*/}

              {/*    <ExpansionPanelDetails>*/}
              {/*      <AuditTrail />*/}
              {/*    </ExpansionPanelDetails>*/}
              {/*  </ExpansionPanel>*/}
              {/*) : (*/}
              {/*  <div />*/}
              {/*)}*/}
            </Grid>
          </Grid>
        </>
      )}
    </AuthContext.Consumer>
  )
}
