// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useState } from "react"
// We keep track of anything that is context dependent on Development, Staging, or Production environment in one file
import env from "../Config/environment"
// We use Auth0 as our current authenication and authorization solution
import { AuthContext, PrivateRoute } from "../Auth"
// All interactions with the remote server are driven from this file
import { useSidebar } from "../Utils/api"
// We use the Material-UI React library as our front end design framework
import { Grid, Toolbar } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
// We load our application css by only bringing in the styles needed for the current page
import { appStyles, theme } from "../Config/theme"
// Prevents rendering of the application and shows a spinning circle while the application is loading
import LoadingScreen from "../Components/Loading/LoadingRing"
// We use React Notifications to show pop up messages to the user
import "react-notifications/lib/notifications.css"
import { NotificationContainer } from "react-notifications"
// We use Ruby on Rails Action Cable to enable push notifications via web sockets
import actionCable from "actioncable"
// The following are HTML/CSS components that are always present regardless of which page in the app our user is currently visiting
import ToTheTop from "../Components/BackToTop/BackToTop" // When the user scrolls down they are provided a button that scrolls back to the top of the page
import Footer from "../Components/Footer/Footer" // Copyright information
import TopNavWithSideDrawer from "../Components/TopNavWithSideDrawer/TopNavWithSideDrawer" // A form of application navigation
import Main from "./Main" // The Router - Controls the content that is shown in the body of the web page the user is currently visiting

// This code opens up a web socket with the remote server allowing the server to push notifications to any page that subcribes to one of its channels
const CableApp = {}
CableApp.cable = actionCable.createConsumer(env.API_WS_URL)

// The core function for our application
export default function App() {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = appStyles()
  // When isLoading = true the application shows the spinning loading circle instead of the main content
  const [isLoading, setIsLoading] = useState(false)
  // All data that needs to be persisted in response to user behavior is stored in a useState object; grouped with all things that should rerender together
  const [data, setData] = useState({
    id: "root",
    name: "Clients",
    children: [],
    clients: [],
    dataSourceUpdates: {},
  })
  // The application uses a left hand navigation scheme that requires a list of clients/engagements provided to the user on application load
  useSidebar(setIsLoading, setData)

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Consumer>
      {({ auth }) => (
        <div className="App">
          <ThemeProvider theme={theme}>
            <div className={classes.root}>
              <CssBaseline />

              <nav className={classes.drawer} aria-label="navigation">
                <TopNavWithSideDrawer admin={auth.user.admin} data={data} />
                <Toolbar
                  id="back-to-top-anchor"
                  className={classes.customizeToolbar}
                />
              </nav>

              <main className={classes.content}>
                <Toolbar />
                <Grid item>
                  <Main
                    cableApp={CableApp}
                    className={classes.drawerHeader}
                    admin={auth.user.admin}
                    setSidebarData={setData}
                    sidebarData={data}
                  />
                  <NotificationContainer
                    clientsWithEngagements={data.clients}
                  />
                  <ToTheTop />
                </Grid>

                <Footer dataSourceUpdates={data.dataSourceUpdates} />
              </main>
            </div>
          </ThemeProvider>
        </div>
      )}
    </AuthContext.Consumer>
  )
}

