import React from "react"
import ReactSpeedometer from "react-d3-speedometer"

const textColor = "grey"

function HealthMeter({ progressPercent }) {

  console.log(progressPercent.toFixed(1))

  const progressString =
    "Progress: " + (progressPercent.toFixed(1).toString() + "%")

  return (
    <ReactSpeedometer
      value={progressPercent}
      maxValue={100}
      needleColor="black"
      textColor={textColor}
      customSegmentStops={[0, 25, 50, 75, 100]}
      segmentColors={["#bf616a", "#d08770", "#ebcb8b", "#a3be8c"]}
      currentValueText={progressString}
      width={450}
      height={300}
      className="speedometer"
      // style="margin-top: 25px;"
    />
  )
}

export default HealthMeter
