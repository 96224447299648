import React, { useState } from "react"
import { Link } from "react-router-dom"

import LinearProgressWithLabel from "../../../Components/ProgressBar/LinearProgressWithLabel"

const useSectionsState = ({ params }) => {
  // When isLoading = true the application shows the spinning loading circle instead of the main content
  const loading = useState(false)

  // This webpage needs access to the individual engagement in order to display its name as requested by Jason
  const engagement = useState([{}])

  // Each AnimatedDownloadButton has its own state that drives whats its display looks like
  const downloadedStatus = useState({
    downloaded: false,
    loading: false,
  })

  const downloadedSnapshot = useState({
    downloaded: false,
    loading: false,
  })

  // The CRUD operations are handled by Material-Table which has its entire state driven by this gridData variable. You can
  // force a rerender of the HTML and CSS by using the setGridData function
  // The long term persistent data retrieved from your database goes into the data attribute
  // The display information for the table goes in the columns attribute. You need to look in the npm modules to see the default
  // HTML and CSS used by Material-Table. If you see a render: attribute that will contain custom HTML and CSS written by our
  // team to override the defualt Material-Table HTML and CSS
  const gridData = useState({
    data: [],
    actionItems: [],
    columns: [
      {
        title: "Name",
        field: "title",
        grouping: false,
        render: (rowData) => (
          <Link
            to={{
              pathname: `/clients/${params.clientId}/engagements/${params.engagementId}/sections/${rowData.id}`,
              state: {
                sectionId: rowData.id,
                engagementId: params.engagementId,
                clientId: params.clientId,
              },
            }}
          >
            {rowData.title}
          </Link>
        ),
        width: "30%",
        cellStyle: { width: "30%" },
        headerStyle: { width: "30%" },
      },
      {
        title: "Status",
        field: "status",
        lookup: {
          Pending: "Pending",
          "In Progress": "In Progress",
          "Pending Validation": "Pending Validation",
          "Pending Supervisory Review": "Pending Supervisory Review",
          Complete: "Complete",
        },
        editable: "never",
      },
      {
        title: "Assigned Area",
        field: "assigned_area",
        editable: "never",
      },
      {
        title: "Progress",
        field: "in_progress_percent",
        editable: "never",
        grouping: false,
        render: (rowData) =>
          rowData ? (
            <LinearProgressWithLabel value={rowData.in_progress_percent} />
          ) : (
            <LinearProgressWithLabel value={0} />
          ),
      },
    ],
    resolve: () => {},
  })

  return {
    loading,
    engagement,
    downloadedStatus,
    downloadedSnapshot,
    gridData,
  }
}

export { useSectionsState }
