// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react";
// We use the Material-UI React library as our front end design framework
import { Grid } from "@material-ui/core"
// We load our application css by only bringing in the styles needed for the current page
import { actionItemSummaryStyles } from "../../Config/theme"
// The following are HTML/CSS components that are present on this page
import LinearProgressWithLabel from "../ProgressBar/LinearProgressWithLabel"

// This component displays each of the action items along with its percent complete underneath the given section when clicked by user
export default function ActionItemSummary(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = actionItemSummaryStyles()()

  // This helper function converts WYSIWYG editor saved valued into proper format for display
  const stringToHTML = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, "text/html");
    if (doc.body.getElementsByTagName("p")[0]) {
      return doc.body.getElementsByTagName("p")[0].innerHTML;
    } else {
      return doc.body.innerHTML;
    }
  }
  // This helper function sorts the action items to make sure we only display the ones relevant to a given section
  const sortedActionItems = () => {
    const matchSectionId = props.sectionId;
    return props.actionItems.filter(function (actionItem) {
      return actionItem.section_id === matchSectionId;
    });
  }
  
  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <>
      <Grid
        container
        justify="space-evenly"
        alignItems="flex-start"
        spacing={1}
        className={classes.root}
      >
        <Grid item xs={1}></Grid>
        <Grid item xs={2} className={classes.title}>Title</Grid>
        <Grid item xs={2} className={classes.title}>Status</Grid>
        <Grid item xs={3} className={classes.titleCenter}>% Complete</Grid>
        <Grid item xs={4}></Grid>
        {sortedActionItems()
          .sort((a, b) => parseInt(a.position) - parseInt(b.position)) // ascending order
          .map((actionItem) => {
            return (
              <>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                  {stringToHTML(actionItem.title)}
                </Grid>
                <Grid item xs={2}>
                  {actionItem.status}
                </Grid>
                <Grid item xs={3}>
                  <LinearProgressWithLabel
                    value={actionItem.in_progress_percent}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
              </>
            );
        })}
      </Grid>
    </>
  )
}