// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useState, useContext } from 'react'
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link, useHistory, Redirect, useParams } from 'react-router-dom'
// We use Auth0 as our current authenication and authorization solution
import { AuthContext } from '../Auth'
// All interactions with the remote server are driven from this file
import { 
  useSection, 
  handleSectionStatusReport,
  handleSetSectionTitle,
  handleSetAssignedArea,
  handleSetDeadline,
  jumpToNewSection,
  reorderActionItems,
  handleSetResponsibleParty,
  handleSetSignoffAuthority,
  handleSetValidationDate,
  handleSetActualCompleteDate,
  handleSetTargetCompleteDate,
  handleSetTargetStartDate,
  handleSetStatus,
  handleSetTitle,
  handleSetProgressPercent,
  handleNewActionItemCreation,
  handleActionItemDeletion,
  handleFileUploadDeletion
} from '../Utils/api'
// We use the Material-UI React library as our front end design framework
import {
  Paper,
  Card,
  CardHeader,
  Container,
  Divider,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  CardContent,
  Box,
  Button,
  FormLabel
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
// We load our application css by only bringing in the styles needed for the current page
import { sectionStyles } from "../Config/theme"
// Prevents rendering of the application and shows a spinning circle while the application is loading
import LoadingScreen from "../Components/Loading/LoadingRing"
// The following are HTML/CSS components that are present on this page
import AutosaveDatePicker from '../Components/SectionParts/AutosaveDatePicker'
import AutosaveDropdown from '../Components/SectionParts/AutosaveDropdown'
import AutosaveInput from '../Components/SectionParts/AutosaveInput'
import AnimatedDownloadButton from "../Components/AnimatedDownloadButton/AnimatedDownloadButton"

// TODO - These components still need to be cleaned up, converted to functions, along with Users.js, Help.js, and AuditTrail.js pages and styles folder
import FlipClock from '../Components/Clock/FlipClock'
import HealthMeter from '../Components/Chart/HealthMeter'
import NewActionItem from '../Components/SectionParts/NewActionItem'
import SingleColumn from '../Components/SectionParts/SingleColumn'
import StatusCount from '../Components/StatusCount/StatusCount'
import TextEditor from '../Components/SectionParts/TextEditor'
import DropdownNavigation from '../Components/SectionParts/DropdownNavigation'

// The core function for the File Uploads "web page"
export default function Section() {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = sectionStyles()()
  // Access the resource ids from the URL bar via the useParams hook
  const params = useParams()
  // Manipulate the URL bar, needed to 'navigate' to a new section
  const history = useHistory()
  // When isLoading = true the application shows the spinning loading circle instead of the main content
  const [isLoading, setIsLoading] = useState(false)
  // Each AnimatedDownloadButton has its own state that drives whats its display looks like
  const [downloadedSectionStatus, setDownloadedSectionStatus] = useState({
    downloaded:false,
    loading:false
  })
  // The majority of this pages state around the Section and its Action Items are driven from  here
  const [actionState, setActionState] = useState({
    isLoading: true,
    sections: [],
    section: {title:""},
    actionItems: [],
    fileUploads: [],
    clientUsers: []
  })
  // Auth context allows us to access our headers and authenicate with the API when calling handleSubmitSchemaMatch
  const contextValue = useContext(AuthContext)
  // Helper method that sorts the action items by status into buckets containing a count for each status
  const statusCount = actionState.actionItems.reduce((categoryCount, item) => {
    categoryCount[item.status] = categoryCount[item.status] + 1 || 1
    return categoryCount
  }, {})
  // Helper function for getting the correct progress percent or returning 0 if there is no progress percent yet
  const getProgress = (arr) => {
    return arr.reduce((acc, val) => {
      return acc + (val.in_progress_percent || 0)
    }, 0)
  }
  // TODO: Refactor this into a full CRUD resource with database persistence
  // Helper function that returns the requested array of choices for the assigned area dropdown dependening on PRODUCTION client id
  const assignedAreaChoices = () => {
    if (params.clientId === '11') {
      // 11, ACBB
      return [
        'BSA Officer',
        'Qualified Personnel and Resources',
        'Governance and Oversight',
        'Policy and Procedures',
        'Internal Controls',
        'Risk Assessment',
        'Independent Testing and Validation',
        'Training',
        'Suspicious Activity Monitoring and Reporting',
        'CIP/CDD/EDD',
        'Currency Transaction Reporting',
        'OFAC/Sanctions',
        'Management Information Systems',
        'Information Security / Cybersecurity',
        'Enterprise Risk Management',
        'Asset/Liability Management'
      ]
    } else if (params.clientId === '12') {
      // 12, HomeFed
      return [
        'Board and Senior Management Oversight',
        'Staffing',
        'Model Validation',
        'Sanctions Screening',
        'BSA - Internal Controls',
        'Credit Administration - Internal Controls',
        'Policies and Procedures',
        'BSA Infrastructure',
        'BSA Reporting',
        'Risk Assessment',
        'BSA Training',
        'Independent Testing',
        'Suspicious Activity Monitoring',
        'CIP/CDD/EDD',
        'Currency Transaction Reporting'
      ]
    } else if (params.clientId === '19') {
      // 19, AndCapital
      return [
        'Information Sharing',
        'Sanctions Screening',
        'BSA Infrastructure',
        'BSA Reporting',
        'Risk Assessment',
        'BSA Training',
        'Independent Testing',
        'Suspicious Activity Monitoring',
        'CIP/CDD/EDD',
        'Currency Transaction Reporting'
      ]
    } else if (params.clientId === '20') {
      // Nodus
      return [
        'BSA Infrastructure',
        'BSA Reporting',
        'Risk Assessment',
        'BSA Training',
        'Independent Testing',
        'Suspicious Activity Monitoring',
        'CIP/CDD/EDD',
        'Currency Transaction Reporting',
        'BSA/AML',
        'OFAC',
        'Information Sharing'
      ]
    } else if (params.clientId === '24') {
      // Quontic
      return [
        'Capital',
        'Asset Quality',
        'Management',
        'Earnings',
        'Liquidity',
        'Sensitivity to Market Risk',
        'Information Technology',
        'Consumer Compliance',
        'BSA/AML Compliance'
      ]
    } else if (params.clientId === '29') {
      // CBW
      return [
        'BSA Infrastructure',
        'BSA Reporting',
        'Risk Assessment',
        'BSA Training',
        'Independent Testing',
        'Suspicious Activity Monitoring',
        'CIP/CDD/EDD',
        'Currency Transaction Reporting',
        'Information Technology', 
        'Credit Operations',
        'Liquidity/Contingency Funding'
      ]
    } else {
      return [
        'BSA Infrastructure',
        'BSA Reporting',
        'Risk Assessment',
        'BSA Training',
        'Independent Testing',
        'Suspicious Activity Monitoring',
        'CIP/CDD/EDD',
        'Currency Transaction Reporting'
      ]
    }
  }
  // Helper function that corrects the UI for newly uploaded files during the current session
  const handleFilesUploaded = (newFilesUpload) => {
    const currentState = actionState.fileUploads
    const newState = currentState.concat(newFilesUpload)
    setActionState((prevState) => ({
      ...prevState,
      fileUploads: newState
    }))
  }

  // The last thing we do after any other setup code and before rendering for the user is reach out to our API
  // for the persistent data that drives this "web page"
  useSection(params, setActionState)

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  // This web page will display a loading circle when isLoading is set to true, otherwise it will display the actual content
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Consumer>
      {({ auth }) => (
        <>
          <Container maxWidth="xl" style={{ width: '100%', top: '0' }}>
            <Link
                to={{
                  pathname: `/clients/${params.clientId}/engagements/${params.engagementId}/sections`,
                  state: {},
                }}
              >
                <Button
                  title="Back to Sections"
                  className={classes.buttons}
                >
                  <ArrowBackIcon /> {"Back to Sections"}
                </Button>
              </Link>
            <FormLabel title={'Jump to Section'}>
              <Typography>Select Section Destination</Typography>
            </FormLabel>
            <DropdownNavigation
              defaultValue={actionState.section.title}
              selections={actionState.sections}
              jumpToNewSection={jumpToNewSection}
              setActionState={setActionState}
              setIsLoading={setIsLoading}
              contextValue={contextValue}
              history={history}
              parentParams={params}
            />

            <Paper className={classes.paper}>
              <Grid item xs={12}>
                <Card variant={'elevation'} border="primary">
                  <CardHeader
                    className="card-blue"
                    title={
                      <AutosaveInput
                        saveInputValue={handleSetSectionTitle}
                        defaultValue={actionState.section.title}
                        stateManager={actionState}
                        stateSetter={setActionState}
                        contextValue={contextValue}
                      />
                    }
                  />

                  <CardContent>
                    <Box display="flex">
                      <Box bgcolor="lightgreen" p={2} flex="auto">
                        <StatusCount label="Complete" value={parseFloat(statusCount.Complete) || 0}/>
                      </Box>
                      <Box bgcolor="khaki" p={2} flex="auto">
                        <StatusCount label="In Progress" value={parseFloat(statusCount['In Progress']) || 0}/>
                      </Box>
                      <Box bgcolor="lightcoral" p={2} flex="auto">
                        <StatusCount label="Pending" value={parseFloat(statusCount.Pending) || 0} />
                      </Box>
                    </Box>

                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      {' '}
                      <Grid item />
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justify="space-evenly"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <HealthMeter progressPercent={100 * (getProgress(actionState.actionItems) / (actionState.actionItems.length * 100)) || 0} />
                      </Grid>

                      <Grid item>
                        <FlipClock deadline={actionState.section.deadline} />
                      </Grid>

                      <Grid item>
                        <AnimatedDownloadButton
                          onClick={() => 
                            handleSectionStatusReport(
                              setDownloadedSectionStatus,
                              params,
                              contextValue,
                              actionState.section
                            )
                          }
                          loading={downloadedSectionStatus.loading}
                          success={downloadedSectionStatus.downloaded}
                          fab={false}
                          buttonName="Download Section Status Report"
                        />
                      </Grid>
                    </Grid>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                          display="block"
                          gutterBottom
                          title={'Click to Expand'}
                          about={'Click to Expand'}
                        >
                          Details (click to expand)
                        </Typography>
                      </ExpansionPanelSummary>

                      <ExpansionPanelDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextEditor
                              section={actionState.section}
                              stuff={actionState.section.body_text}
                              title={false}
                              editedField="body_text"
                              isSection
                            />
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <Grid item xs={12}>
                      <Typography className="card-blue-mini" variant="body1">
                        Report of Examination
                      </Typography>
                      <TextEditor
                        section={actionState.section}
                        stuff={actionState.section.roe_comments_text}
                        title={false}
                        editedField="roe_comments_text"
                        isSection
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="card-blue-mini" variant="body1">
                        Audit Comments
                      </Typography>
                      <TextEditor
                        section={actionState.section}
                        stuff={actionState.section.audit_issues_text}
                        title={false}
                        editedField="audit_issues_text"
                        isSection
                      />

                      <Typography className="card-blue-mini" variant="body1">
                        Assigned Area
                      </Typography>
                      <AutosaveDropdown
                        saveInputValue={handleSetAssignedArea}                        
                        defaultValue={actionState.section.assigned_area}
                        selections={assignedAreaChoices()}
                        stateManager={actionState}
                        stateSetter={setActionState}
                        contextValue={contextValue}
                      />

                      <Divider variant={'inset'} />

                      <Typography className="card-blue-mini">
                        Deadline
                      </Typography>
                      <AutosaveDatePicker
                        startDate={actionState.section.deadline}
                        saveInputValue={handleSetDeadline}
                        title={"Deadline"}
                        stateManager={actionState}
                        stateSetter={setActionState}
                        contextValue={contextValue}
                      />
                    </Grid>

                    <br />

                    <CardHeader
                      title="Action Plan"
                      className="card-green"
                      action={
                        <NewActionItem
                          handleNewActionItemCreation={handleNewActionItemCreation}
                          actionState={actionState}
                          setActionState={setActionState}
                          contextValue={contextValue}
                        />
                      }
                    />

                    <SingleColumn
                      clientId={params.clientId}
                      engagementId={params.engagementId}
                      contextValue={contextValue}
                      actionState={actionState}
                      setActionState={setActionState}
                      actionItems={actionState.actionItems}
                      reorderActionItems={reorderActionItems}           
                      fileUploads={actionState.fileUploads}
                      handleActionItemDeletion={handleActionItemDeletion}
                      responsibleParty={handleSetResponsibleParty}
                      signoffAuthority={handleSetSignoffAuthority}
                      validationDate={handleSetValidationDate}
                      actualCompleteDate={handleSetActualCompleteDate}
                      targetCompleteDate={handleSetTargetCompleteDate}
                      targetStartDate={handleSetTargetStartDate}
                      status={handleSetStatus}
                      actionItemTitle={handleSetTitle}
                      progressPercent={handleSetProgressPercent}
                      handleFilesUploaded={handleFilesUploaded}
                      handleFileUploadDeletion={handleFileUploadDeletion}
                    />
                  </CardContent>
                </Card>

                {/*FIXME: audit trail*/}
                {/*{auth.user.admin ? (*/}
                {/*  <ExpansionPanel>*/}
                {/*    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>*/}
                {/*      <Typography>Show AuditTrail</Typography>*/}
                {/*    </ExpansionPanelSummary>*/}

                {/*    <ExpansionPanelDetails>*/}
                {/*      <AuditTrail />*/}
                {/*    </ExpansionPanelDetails>*/}
                {/*  </ExpansionPanel>*/}
                {/*) : (*/}
                {/*  <div />*/}
                {/*)}*/}
              </Grid>
            </Paper>
          </Container>
        </>
      )}
    </AuthContext.Consumer>
  )
}
