// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useState } from "react"
// Link is the primary method of switching 'web pages' inside of the application via resource ids from useParams
import { Link, useParams } from "react-router-dom"
// We use Auth0 as our current authenication and authorization solution
import { AuthContext } from "../Auth"
// All interactions with the remote server are driven from this file
import { useAuditTrail } from "../Utils/api"
// We use the Material-UI React library as our front end design framework
import { Hidden, Grid, Button } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
// We load our application css by only bringing in the styles needed for the current page
import { auditTrailStyles } from "../Config/theme"
// Prevents rendering of the application and shows a spinning circle while the application is loading
import LoadingScreen from "../Components/Loading/LoadingRing"
// We use Material-Table for CRUD operations on application resources
import MaterialTable from "material-table"
import DataTableIcons from "../Components/Icons/DataTableIcons"
import { colors } from '../Config/colors'

// The core function for the Engagements "web page"
export default function AuditTrail(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = auditTrailStyles()()
  // Access the resource ids from the URL bar via the useParams hook
  const params = useParams()
  // When isLoading = true the application shows the spinning loading circle instead of the main content
  const [isLoading, setIsLoading] = useState(false)
  // Helper function for formatting ISO 8601 datetime format into human readable format
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute:"numeric"}
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  // Helper function for formatting link and title to audit trail resources
  const formatLinkAndTitle = (rowData) => {
    let ourModel ={}
    let newModel ={}
    let originalModel ={}
    let changedItem = 'No Actual Change'

    if(rowData.model_type === 'ActionItem' || rowData.model_type === 'FileUpload'){
      if(rowData.action !== 'destroyed' && rowData.action !== 'created'){
        rowData.new_model.split("***").forEach((item) => {
          let splitString = item.split(":::")
          ourModel[splitString[0]] = splitString[1]
          newModel[splitString[0]] = splitString[1]
        })
        rowData.original_model.split("***").forEach((item) => {
          let splitString = item.split(":::")
          originalModel[splitString[0]] = splitString[1]
        })
      }else if(rowData.action === 'destroyed'){
        rowData.original_model.split("***").forEach((item) => {
          let splitString = item.split(":::")
          ourModel[splitString[0]] = splitString[1]
          originalModel[splitString[0]] = splitString[1]
        })
      }else if(rowData.action === 'created'){
        rowData.new_model.split("***").forEach((item) => {
          let splitString = item.split(":::")
          ourModel[splitString[0]] = splitString[1]
          originalModel[splitString[0]] = splitString[1]
        })
      }  
      
      Object.keys(newModel).forEach(function(key) {        
        if(originalModel[key] !== newModel[key] && key !== 'updated_at'){
          changedItem = key
        }    
      })
    }   


    let link;
    let title;

    if(rowData.model_type === 'Engagement'){
      link = `/clients/${params.clientId}/engagements/${params.engagementId}/sections`
    }else if (rowData.model_type === 'Section'){
      link = `/clients/${params.clientId}/engagements/${params.engagementId}/sections/${ourModel['id']}`
    }else if (rowData.model_type === 'ActionItem'){
      link = `/clients/${params.clientId}/engagements/${params.engagementId}/sections/${ourModel['section_id']}`
      title = ourModel['title']
    }else if (rowData.model_type === 'FileUpload' || rowData.model_type === 'File Upload'){
      // Need to write a rake task that adds the section id to the file upload audit trail items before this can be added
      //link = `/clients/${params.clientId}/engagements/${params.engagementId}/sections/${ourModel['id']}`
      title = ourModel['original_filename']
    }

    if(rowData.model_type === 'ActionItem'){
      return (
        <div>     
          <Link
            to={{
              pathname: link,
              //state: {
              //  sectionId: section_id,
              //  engagementId: params.engagementId,
              //  clientId: params.clientId,
              //},
            }}
          >
            Link
          </Link>
          
          <p style={{margin: '0'}}>
          <span style={{fontWeight:'bold'}}>Title:</span>{" " + title}
          </p>
          <p style={{margin: '0'}}>
          <span style={{fontWeight:'bold'}}>Changed Item:</span>{" " + changedItem}
          </p>
        </div>        
      )}
      else if(rowData.model_type === 'FileUpload' || rowData.model_type === 'File Upload'){
        return (
          <div>     
            {/* <Link
              to={{
                pathname: link,
                //state: {
                //  sectionId: section_id,
                //  engagementId: params.engagementId,
                //  clientId: params.clientId,
                //},
              }}
            >
              Link
            </Link> */}
            
            <p style={{margin: '0'}}>
            <span style={{fontWeight:'bold'}}>Title:</span>{" " + title}
            </p>
          </div>        
        )
      }else{
      return (
        <div>
          <Link
            to={{
              pathname: link,
              //state: {
              //  sectionId: section_id,
              //  engagementId: params.engagementId,
              //  clientId: params.clientId,
              //},
            }}
          >
            Link
          </Link>
        </div>        
      )
    }
    
  }
  // The CRUD operations are handled by Material-Table which has its entire state driven by this gridData variable. You can
  // force a rerender of the HTML and CSS by using the setGridData function
  // The long term persistent data retrieved from your database goes into the data attribute
  // The display information for the table goes in the columns attribute. You need to look in the npm modules to see the default
  // HTML and CSS used by Material-Table. If you see a render: attribute that will contain custom HTML and CSS written by our
  // team to override the defualt Material-Table HTML and CSS
  
  const [actionState, setActionState] = useState({
    isLoading: true,
    sections: [],
    section: {title:""},
    actionItems: [],
    fileUploads: [],
    clientUsers: []
  })
  
  const [gridData, setGridData] = useState({
    data: [],
    fileUploadKeys:[],
    columns: [
      {
        title: "User Email",
        field: "user_email",
      },
      {
        title: "Area",
        field: "model_type"
      },
      {
        title: "Action Taken",
        field: "action"
      },
      {
        title: "Time Of Action",
        render: rowData => <p style={{margin: '0'}}>{formatDate(rowData.created_at)}</p>
      },
      {
        title: "Change Location",
        render: rowData => <div style={{margin: '0'}}>{formatLinkAndTitle(rowData)}</div>

      }
    ],
  })
  // Material-Table options
  const options = {
    headerStyle: {
      backgroundColor: colors.MIDNIGHT_BLUE.hex,
      color: "white",
    },
    sorting: true,
    rowHover: true,
    actionsColumnIndex: -1,
    filterType: "dropdown",
    pageSize: 10,
    exportButton: true,
    addRowPosition: "first",
  }
  // Material-Table names and labels overrides
  const localization = {
    pagination: {
      labelDisplayedRows: "{from}-{to} of {count}",
    },
    toolbar: {
      nRowsSelected: "{0} row(s) selected",
    },
    header: {
      actions: "Admin Actions",
    },
    body: {
      emptyDataSourceMessage: "No Audit Trail to display",
      filterRow: {
        filterTooltip: "Filter",
      },
    },
  }

  // The last thing we do after any other setup code and before rendering for the user is reach out to our API
  // for the persistent data that drives this "web page"
  useAuditTrail(params, setIsLoading, setGridData)

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  // This web page will display a loading circle when isLoading is set to true, otherwise it will display the actual content
  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthContext.Consumer>
      {({ auth }) => (
        <>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item>
              <Link
                to={{
                  pathname: `/clients/${params.clientId}/engagements/${params.engagementId}/sections`,
                  state: {},
                }}
              >
                <Button
                  title="Back to Sections"
                  className={classes.leftSideNavigationButton}
                >
                  <ArrowBackIcon /> {"Back to Sections"}
                </Button>
              </Link>
            </Grid>

            <Hidden>
              <Grid item />
              <Grid item />
            </Hidden>

            <Grid item xs={12}>
              <div style={{ maxWidth: "100%" }}>
                <MaterialTable
                  isLoading={isLoading}
                  data={gridData.data}
                  icons={DataTableIcons}
                  title=" Audit Trail"
                  columns={gridData.columns}
                  options={options}
                  localization={localization}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </AuthContext.Consumer>
  )
}
