// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useEffect } from "react"
// We use the Material-UI React library as our front end design framework
import { Select, MenuItem } from '@material-ui/core';

// This function provides a select/dropdown menu that saves its selection to the database
export default function AutosaveDropdown(props) {
  // This state allows us to keep the default value displaying
  const [currentSelection, setCurrentSelection] = React.useState("")
  // UseEffect will set the default value on initial render
  useEffect(() => {
    setCurrentSelection(props.defaultValue)
  }, [props.defaultValue])
  // When the user selects a new dropdown item we want to update the display and save the new value to the database
  const changeInSelection = (newSelection) => {
    setCurrentSelection(newSelection)
    props.saveInputValue(newSelection,props.stateManager,props.stateSetter,props.contextValue)
  }

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  // This web page will display a loading circle when isLoading is set to true, otherwise it will display the actual content
  return (
    <>
      <Select
        value={currentSelection}
      >
        {props.selections.map((selection, i) => {
          return (
            <MenuItem
              onClick={() => changeInSelection(selection)}
              className="autosave-dropdown-selection"
              key={i}
              value={selection}
            >
              {selection}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
}