// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// We load our application css by only bringing in the styles needed for the current page
import { animatedDownloadButtonStyles } from "../../Config/theme"
// We use clsx to conditionally apply a given css classname only when a prop evaulates to true
import clsx from "clsx"
// We use the Material-UI React library as our front end design framework
import { Button, Fab, Tooltip, CircularProgress } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import SaveIcon from "@material-ui/icons/Save"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"

// This function displays a button expected to be used for downloading from a remote server and will show a loading icon while waiting for 
// the servers response and after the response it will change its display to indicate to the user the download was complete
export default function AnimatedDownloadButton(props) {
  // This is how we keep all of our CSS in one place "Config/theme" and only load in the parts that are relevant to the current page
  const classes = animatedDownloadButtonStyles()()
  // The buttons display will change based on whether a file has or has not been downloaded
  const buttonClassname = clsx({
    [classes.buttonSuccess]: props.success,
  })

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (    
    <div className={classes.root}>
      {props.fab ? (
        <div className={classes.wrapper}>
          <Fab
            aria-label="save"
            color="primary"
            className={buttonClassname}
            onClick={props.onClick}
          >
            {props.success ? <CheckIcon /> : <SaveIcon />}
          </Fab>
          {props.loading && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
      ) : (
        <div className={classes.wrapper}>
          <Tooltip title={"Click to download report"}>
            <span>
              <Button
                variant="contained"
                color="primary"
                className={buttonClassname}
                onClick={props.onClick}
                startIcon={<CloudDownloadIcon />}
                disabled={props.loading || props.disabled}
              >
                {props.success ? "Downloaded!" : props.buttonName}
              </Button>
            </span>
          </Tooltip>

          {props.loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      )}
    </div>
  )
}