import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import env from "../../Config/environment"
import "./editor.css"
import { Card } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { AuthContext } from "../../Auth"
import { NotificationManager } from "react-notifications"

const errorNotification = (error) => {
  return NotificationManager.error(
    error.response.data.message,
    error.response.data.title,
    200000
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const timer = ({ debounceTime = 3000, saveFunction = null } = {}) => {
  let clean = true
  let oldState = null
  let timeout = null

  const save = (editorState) => {
    clean = true
    oldState = editorState

    if (saveFunction) {
      saveFunction(editorState)
    }
  }

  const onChange = (editorState) => {
    if (!editorState) {
      return editorState
    }
    if (!oldState) {
      oldState = editorState
    }
    if (editorState.getCurrentContent() !== oldState.getCurrentContent()) {
      clean = false
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(save, +debounceTime, editorState)
    }
    return editorState
  }
  const isClean = () => clean
  return {
    onChange,
    isClean,
  }
}

function TextEditor(props) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [currentEditorState, setCurrentEditorState] = useState(
    EditorState.createEmpty()
  )

  const [focused, setFocused] = useState(true)
  const classes = useStyles()

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  const contextValue = useContext(AuthContext)

  const saveEditorState = (close) => {
    if (close) {
      if (props.isSection) {
        axios
          .put(
            `${env.API_BASE_URL}/sections/${props.section.id}`,
            {
              [`${props.editedField}`]: draftToHtml(
                convertToRaw(editorState.getCurrentContent())
              ),
            },
            {
              headers: contextValue.getTokens(),
            }
          )
          .then((response) => {
            setFocused(true)
            setCurrentEditorState(editorState)
          })
          .catch((error) => {
            errorNotification(error)
          })
      } else {
        axios
          .put(
            `${env.API_BASE_URL}/action_items/${props.actionItem.id}`,
            {
              [`${props.editedField}`]: draftToHtml(
                convertToRaw(editorState.getCurrentContent())
              ),
            },
            {
              headers: contextValue.getTokens(),
            }
          )
          .then((response) => {
            setFocused(true)
            setCurrentEditorState(editorState)
          })
          .catch((error) => {
            errorNotification(error)
          })
      }
    }
  }

  useEffect(() => {
    if (props.stuff) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(props.stuff).contentBlocks
          )
        )
      )
      setCurrentEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(props.stuff).contentBlocks
          )
        )
      )
    }
  }, [props.stuff, setCurrentEditorState])

  return focused ? (
    <Card
      component="span"
      onClick={() => setFocused(false)}
      className="tight-card-body-cursor-focused"
    >
      <Editor
        editorState={editorState}
        plugins={[timer]}
        toolbarHidden={focused || props.title}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        onBlur={() => {
          setFocused(true)
          saveEditorState(true)
        }}
        toolbar={{
          options: ["inline", "list"],
        }}
      />
    </Card>
  ) : (
    <Card className={classes.paper} component="span">
      <Editor
        editorState={editorState}
        plugins={[timer]}
        readOnly={focused}
        toolbarHidden={focused || props.title}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        onBlur={() => {
          setFocused(true)
          saveEditorState(true)
        }}
        toolbar={{
          options: ["inline", "list"],
        }}
        spellCheck={true}
        stripPastedStyles={true}
      />
    </Card>
  )
}

export default TextEditor
