import React, { useEffect } from "react"
import { Dropdown } from "react-bootstrap"

export default function DropdownNavigation(props) {
  const [currentSelection, setCurrentSelection] = React.useState("")

  useEffect(() => {
    setCurrentSelection(props.defaultValue)
  }, [props.defaultValue])

  return (
    <>
      <Dropdown className="autosave-dropdown">
        <Dropdown.Toggle
          className="autosave-dropdown-toggle"
          id="autosaveDropdown"
        >
          <p className="autosave-dropdown-button-label">{currentSelection}</p>
        </Dropdown.Toggle>
        <Dropdown.Menu aria-labelledby="autosaveDropdown">
          {props.selections.map((selection, i) => {
            return(
              <Dropdown.Item
                className="autosave-dropdown-selection"
                onClick={() => props.jumpToNewSection(selection,props.setActionState,props.setIsLoading,props.contextValue,props.history,props.parentParams)}
                key={selection.id}
              >
                {selection.title}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
