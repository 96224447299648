// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React from "react"
// We use the Material-UI React library as our front end design framework
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
// The Material-UI datepicker requires this as an input
import DateFnsUtils from "@date-io/date-fns"

// This function displays a date picker and is expected to be tied to an API save action
export default function AutosaveDatePicker(props) {
  // Allows us to pass the controlling component the start or end date status of this button and the users selection
  const handleChange = (date) => {
    props.saveInputValue(
      date,
      props.stateManager,
      props.stateSetter,
      props.contextValue
    )
  }

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          // className="autosave-date-picker"
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id={props.id}
          label={props.title}
          value={props.startDate}
          onChange={handleChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  )
}
