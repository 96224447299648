// Anything that needs to change dependent on whether the code is running development, staging, or production

const env = {
  // This is your localhost server
  dev: {
    // This supplies a relative URL to the common root "/" of your application and should always match the value
    // in your nginx.conf file. This root allows us to fork the software into multiple versions per client when
    // we sell subscriptions that require the hardware to be unique to the subscription via /client_name in place
    // of /app_name that we use for the internal SME's and smaller tenanted clients
    ROUTER_BASE_NAME: "/trak",
    // This is the URL needed to submit requests to your servers router and should always match the DNS of the
    // server plus the value in your routes scope
    API_BASE_URL: "http://localhost:3000/api/trak",
    // These are the required variables for our authentication service Auth0
    AUTH0_DOMAIN: "krbigdata.auth0.com",
    AUTH0_AUDIENCE: "https://dev.krbigdata.com",
    AUTH0_CLIENT_ID: "JenkyAcMySufbeCW3zHvgUmpAJ32n2ZT",
    AUTH0_REDIRECT_URI: "http://localhost:4200/trak",
    // Websockets allow us to send push notifications from our backend code
    API_WS_URL: "ws://localhost:3000/cable",
  },
  // This is a remote Azure server identical to the production environment but with a fake database
  // After you have finished developing a feature, you should deploy it to staging and make sure it works
  // prior to submitting a pull request to production
  staging: {
    // This supplies a relative URL to the common root "/" of your application and should always match the value
    // in your nginx.conf file. This root allows us to fork the software into multiple versions per client when
    // we sell subscriptions that require the hardware to be unique to the subscription via /client_name in place
    // of /app_name that we use for the internal SME's and smaller tenanted clients
    ROUTER_BASE_NAME: "/trak",
    // This is the URL needed to submit requests to your servers router and should always match the DNS of the
    // server plus the value in your routes scope
    API_BASE_URL: "https://trak-staging.kaufmanrossin.com/api/trak",
    // These are the required variables for our authentication service Auth0
    AUTH0_DOMAIN: "krbigdata.auth0.com",
    AUTH0_AUDIENCE: "https://dev.krbigdata.com",
    AUTH0_CLIENT_ID: "JenkyAcMySufbeCW3zHvgUmpAJ32n2ZT",
    AUTH0_REDIRECT_URI: "https://trak-staging.kaufmanrossin.com/trak",
    // Websockets allow us to send push notifications from our backend code
    API_WS_URL: "wss://trak-staging.kaufmanrossin.com/cable",
  },
  // This is a remote Azure server containing our live user database and accessed by our subscription customers / SME's
  production: {
    // This supplies a relative URL to the common root "/" of your application and should always match the value
    // in your nginx.conf file. This root allows us to fork the software into multiple versions per client when
    // we sell subscriptions that require the hardware to be unique to the subscription via /client_name in place
    // of /app_name that we use for the internal SME's and smaller tenanted clients
    ROUTER_BASE_NAME: "/trak",
    API_BASE_URL: "https://trak.kaufmanrossin.com/api/trak",
    // These are the required variables for our authentication service Auth0
    AUTH0_DOMAIN: "krbigdata.auth0.com",
    AUTH0_AUDIENCE: "https://dev.krbigdata.com",
    AUTH0_CLIENT_ID: "JenkyAcMySufbeCW3zHvgUmpAJ32n2ZT",
    AUTH0_REDIRECT_URI: "https://trak.kaufmanrossin.com/trak",
    // Websockets allow us to send push notifications from our backend code
    API_WS_URL: "wss://trak.kaufmanrossin.com/cable",
  },
}

export default {
  ...env[process.env.REACT_APP_STAGE],
}
