// All react components require React and frequently utilize the various "useSomeName" library hooks that come with React
import React, { useEffect } from "react"

// This function provides us with an input field that the user can type in and when they unselect the field the new value
// will be automatically saved to the database
export default function AutosaveInput(props) {
  // This state allows us to keep the default value displaying
  const [currentValue, setCurrentValue] = React.useState("")
  // UseEffect will set the default value on initial render
  useEffect(() => {
    setCurrentValue(props.defaultValue)
  }, [props.defaultValue])
  // When the user types a new character we want to display it but we don't want to make a database save until they are done
  const handleChange = (event) => {
    setCurrentValue(event.target.value)
  }
  // When the user is done and navigates away from the input box we will save the entire new set of changes
  const handleBlur = () => {
    props.saveInputValue(currentValue,props.stateManager,props.stateSetter,props.contextValue)
  }

  // The HTML and CSS is represented by JSX which is always contained within the return () block of the function
  // This web page will display a loading circle when isLoading is set to true, otherwise it will display the actual content
  return (
    <>
      <input
        className="autosave-input"
        name="autosaveInput"
        type="text"
        value={currentValue}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </>
  )
}