import React from "react"
import { Button, Modal, Form } from "react-bootstrap"
import axios from "axios"
import env from "../../Config/environment"
import { AuthContext } from "../../Auth"
import { NotificationManager } from "react-notifications"

const errorNotification = (error) => {
  return NotificationManager.error(
    error.response.data.message,
    error.response.data.title,
    200000
  )
}

class NewActionItem extends React.Component {
  static contextType = AuthContext
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleNewActionItem = this.handleNewActionItem.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

    this.state = {
      show: false,
      title: "",
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow() {
    this.setState({ show: true })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleNewActionItem() {
    this.props.handleNewActionItemCreation(
      this.state.title,
      this.handleClose, 
      this.props.actionState, 
      this.props.setActionState, 
      this.props.contextValue
    )
  }

  render() {
    return (
      <>
        <Button
          className="white-green-link-button align-middle float-right"
          onClick={this.handleShow}
        >
          New Action Item
        </Button>
        <Modal
          className="new-modal"
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header className="custom-modal-header" closeButton>
            <Modal.Title>New Action Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formActionItemTitle">
                <Form.Label>Action Item Title</Form.Label>
                <Form.Control
                  name="title"
                  type="text"
                  placeholder="Enter Action Item Text"
                  onChange={this.handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={this.handleNewActionItem}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default NewActionItem
